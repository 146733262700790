import * as React from 'react';
import { breakpoint, breakpointMax } from '../../../style';
import { css } from '@emotion/react';
import HexaBold from './HexaBold.svg';

export const style = {
  footer: css`
    background: #425563;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-decoration: underline;
      text-decoration-color: rgba(255, 255, 255, 0.5);
    }
    @media (max-width: ${breakpointMax}) {
      background: 
        url(${HexaBold}) no-repeat center right,
      #425563;
      background-position-y: 27%;
        .css-b9v4n {
          margin-bottom: 0px;
        }
    }
  `,
  footerContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 3em;
    margin: auto;
    padding-bottom: 0;
    width: 80%;
    @media (max-width: ${breakpointMax}) {
      text-align: center;
      padding: 0;
    }
  `,
  footerCol: (index: number) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    a {
      cursor: pointer;
      color: #f4f6f7;
      text-decoration: none;
      :hover,
      :active,
      :focus {
        color: #ffffff;
        transition: all ease 0.2s;
      }
    }
    @media (max-width: ${breakpointMax}) {
      margin: 0px;
    }
  `,
  footerContainerRow: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      padding-bottom: 36px;
      padding-top: 36px;
    }
  `,
  footerTablet: css`
    display: flex;
    width: 100%;
    min-height: 230px;
    margin-bottom: 3em;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      margin-bottom: 0px;
      min-height: 0px;
    }
  `,
  footerTitle: css`
    font-size: 14px;
    font-weight: 800;
    line-height: 18px;
    margin-bottom: 20px;
    color: #f4f6f7;
  `,
  mobileCondition: (expend?: boolean) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      display: ${expend ? 'inherit' : 'none'};
      gap: 32px;
    }
  `,
  footerLabel: css`
    font-size: 14px;
    font-weight: 200;
    line-height: 18px;
    margin-bottom: 8px;
    color: #f4f6f7;
  `,
  footerSeparator: css`
    height: 3em;
  `,
  footerBottom: css`
    background: #253845;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 122px;
    a {
      font-weight: 600;
      font-size: 14px;
      color: #425563;
      cursor: pointer;
      text-decoration: none;
      :hover,
      :active,
      :focus {
        color: #d4d4d4;
        transition: all ease 0.2s;
      }
    }
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      height: 525px;
    }
  `,
  footerBottomLeft: css`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width: ${breakpointMax}) {
      width: unset;
      margin-top: 15px;
    }
  `,
  footerBottomRight: css`
    position: relative;
    height:100%;
    font-weight: 600;
    font-size: 14px;
    color: #425563;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
      span {
        color: #ffffff
      }
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      }
    }
  `,

  footerLinks: css`
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    a {
      color: #A4B6C2;
      margin-right: 8px;
      margin-top: 4px;
      margin-bottom: 4px;
      
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media (max-width: 1150px) {
      display: contents;
      margin-top: 29px;
        a {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
  `,
  footerLogo: css`
    width: 15%;
    text-decoration: none;
    @media (max-width: ${breakpointMax}) {
      display: contents;
    }
    @media (min-width: ${breakpointMax}) and (max-width: 1150px) {
      display: contents;
    }
  `,
  footerSocial: css`
    width: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      width: 50px;
      justify-content: center;
      display: flex;
      border-right: solid 1px rgba(255, 255, 255, 0.2);
      text-decoration: none;
      &:last-child {
        border-right: none;
      }
    }
    a {
      text-decoration: none;
      color: white;
    }
    @media (max-width: ${breakpointMax}) {
      display: flex;
      width: 30%;
      gap: 37px;
      padding-bottom: 25px;
      padding-top: 25px;
      span {
        width: 50px;
        justify-content: center;
        display: flex;
        border-right: none;
        }
      }
    }
  `,
  verticalLine: css`
    height: 52px;
    border-left: solid 1px rgba(255, 255, 255, 0.2);
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  bgImage: css`
    position: absolute;
    opacity: 0.3;
    margin-right: 210px;
    right: -178px;
    pointer-events: none;
    margin-top: -49px;
    height: 213px;
  `,
  bottomText: css`
    @media (max-width: ${breakpointMax}) {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.1px;
      margin-top: 29px;
      margin-bottom: 24px;
    }
  `,
};

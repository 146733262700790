/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { style } from './index.style';
import Select from "react-select";

export interface InputSortProps {
  sort?: any;
  baseValues?: any;
  onSortChange: (sort: any) => void;
}

export function InputSort({ sort, baseValues, onSortChange }: InputSortProps) {
  const options: any[] = React.useMemo(() => {
    const optionValues: any[] = [];

    if (baseValues)
      Object.entries(baseValues).map(([key, value]) => {
        const item = value as any;
        if (value)
          optionValues.push({
            name: item.name,
            value: key,
            default: item.default,
            field: item.field,
            dir: item.dir,
          });
      });

    return optionValues;
  }, [baseValues]);

  const handleSortChange = (selectedOption: any): void => {
    let newSort = sort;
    if (options)
      options.forEach((option: any) => {
        if (selectedOption.value === option.value) newSort = option;
      });
    onSortChange(newSort);
  };

  const formattedOptions = options.map((item: any) => ({
    label: item.name,
    value: item.value,
  }));

  return (
    <div>
      <div css={style.inputSort}>
        <span>Sort by</span>
        <Select
          isClearable={false}
          isSearchable={false}
          onChange={handleSortChange}
          options={formattedOptions}
          placeholder="Type"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              marginTop: "5px",
              color: "black",
              borderRadius: "4px",
              borderColor: state.isFocused ? "#22AA60" : "#d9dee2",
              height: 38,
              width: 150,
              boxShadow: state.isFocused ? "0 0 0 1px #22AA60" : "none",
              "&:hover": {
               borderColor: "#22AA60",
                boxShadow: "0 0 0 1px #22AA60",
              },
              "&:focus": {
                borderColor: "#22AA60",
                boxShadow: "0 0 0 1px #22AA60",
              },
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: "none",
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              color: "#22AA60 !important",
              "&:hover": {
                color: "#22AA60 !important",
              },
            }),
          }}
        />
      </div>
    </div>
  );
}

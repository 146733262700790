/** @jsx jsx */

/* eslint-disable */
import { jsx, css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { style } from './index.style';
import { Tabs, Tab, Icon, Loader, OptionsData } from '@bdl-cmn-shared-packages-npm/design-system';
import { data as dataMapper, searchSortData } from '../dataMapper';
import { SecuritiesListView } from '../../../UI/list/security';
import { IssuersListView } from '../../../UI/list/issuer';
import { ProgrammeListView } from '../../../UI/list/programme';
import { DocumentsListView } from '../../../UI/list/document';
import SearchResult from '../../../../../interfaces/search/search-result';
import { TabValue } from '../..';
import { DEFAULT_FILTERS, Filters } from '../../useFilters';
import { Customizer, Density } from '../../../UI/list/customizer/customizer.component';
import { LuxXPrimeListView } from '../../../UI/list/luxXPrime';
import useTagFilters from '../useTagFilters';
import { IndexesListView } from '../../../UI/list/index';
import { luxseGatewayGraphqlClient } from '../../../../../luxse-gateway-graphql-client';
import { GET_LUXXPRIME_FILTER_DATA } from '../../../../../graphql';
import { ContentListView } from '../../../UI/list/contentSearch';
import { DataType } from '../../../../../enums/data-type';
import useFilterHandlers from '../../../../hooks/search/useFilterHandlers';

export interface SearchResultsTabsProps {
  data: SearchResult;
  onPaginationUpdate: (pageNumber: number) => void;
  onTabChange: (tabValue: TabValue) => void;
  currentPageNumber: number;
  filters: Filters;
  sort: any;
  onFiltersChange: (filters: Filters) => void;
  onSortChange: (sort: any) => void;
  loading: boolean;
}

const colsTpl: any = {
  securities: [],
  issuers: [],
  documents: [],
  programmes: [],
  luxXPrime: [],
  indices: [],
};

export const SearchResultsTabsContent = (props: SearchResultsTabsProps): JSX.Element => {
  const {
    data,
    onPaginationUpdate,
    onTabChange,
    currentPageNumber,
    filters,
    sort,
    onFiltersChange,
    onSortChange,
    loading,
  } = props;

  const [selectedCols, setSelectedCols] = useState<any>(colsTpl);
  const [density, setDensity] = useState<Density>('medium');
  const [sortData, setSortData] = useState<any>(searchSortData);

  const [names, setNames] = useState<string>("");

  const [issuerNameTags, setIssuerNameTags] = useState<Issuer[]>([]);

  const [loader, setLoader] = useState<boolean>(false);

  const tagFilters = useTagFilters(filters);

  let tmpNames = "";
  let tmpData: OptionsData[] = [];

  const { handleResetFilter, handleResetAllFilters } = useFilterHandlers(filters, onFiltersChange, tagFilters, tmpData);

  class Issuer {
    id: string
    name: string

    constructor(name: string, id: string) {
      this.name = name;
      this.id = id;
    }
  }

  async function getTagsForLuxXPrime(filters: Filters) {
    setLoader(true);
    tmpNames = "";
    setIssuerNameTags([]);
    const client = luxseGatewayGraphqlClient;
    const myPromise: Promise<any> = new Promise((resolve, reject) => {
      return resolve(client.query({
        query: GET_LUXXPRIME_FILTER_DATA,
      }));
    });
    const results = await myPromise;

    tmpData = [];

    results?.data?.securitiesByTag.map((security: any) => {
      if (tmpData[security.issuers[0].id] === undefined)
        tmpData[security.issuers[0].id] = security.issuers[0].name;
    }
    );

    filters?.issuerIds?.map(id => {
      const newIssuer = new Issuer(tmpData[parseInt(id)].toString(), id);
      if (!issuerNameTags.includes(newIssuer))
        issuerNameTags.push(newIssuer);
      tmpNames = tmpNames + newIssuer.name.replace(/,/g, " ") + ",";
      tagFilters.luxXPrime.filter((elem) => elem.name == "issuerIds")[0].value = tmpNames;
    });

    setIssuerNameTags(issuerNameTags);
    setNames(tmpNames);
    setLoader(false);
  };
  useEffect(() => {
    if (tagFilters != null && tagFilters.luxXPrime != null && filters?.issuerIds != null && !issuerNameTags.length) {
      getTagsForLuxXPrime(filters);
    }
  }, [tagFilters, filters]);

  const tabs = [
    { type: "all", label: "All" },
    { type: "webpage", label: "Web pages", category: "webpage" },
    { type: "blogpost", label: "Blog posts", category: "blogpost" },
    { type: "pressrelease", label: "Press releases", category: "pressrelease" },
    { type: "brochure", label: "Brochures", category: "brochure" },
    { type: "form", label: "Forms", category: "form" }
  ];

  if(!data){
    return (
    <div>
      <div css={style.tabs}>
        <div css={style.noDataMessage}>Data will be displayed when you have filled in the search field</div>
      </div>
    </div>)
  }

  return (
    <div>
      <div css={style.tabs}>
        { filters.dataType === 'webpage' || filters.dataType === 'all' || filters.dataType === 'blogpost' || filters.dataType === 'pressrelease' || filters.dataType === 'brochure' || filters.dataType === 'form' ? 
          (
            <Tabs>
              {tabs.map(tab => (
                <Tab
                key={tab.type}
                label={`${tab.label}`}
                onTabChange={() => onTabChange(tab.type as DataType)}
                selected={filters.dataType === tab.type}
                css={data?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.webPages?.filters[tab.type as keyof typeof data.webPages.filters]?.length === 0}
              >
                <ContentListView
                  searchResults={data}
                  data={data}
                  dataType={tab.type}
                  loadingPage={loading && filters.dataType === tab.type}
                  density={density}
                  onPaginationUpdate={onPaginationUpdate}
                  currentPageNumber={currentPageNumber}
                  totalResultSize={data?.totalHits}
                  selectedCols={selectedCols}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  sortData={sortData}
                  sort={sort}
                  onSortChange={onSortChange}
                />
              </Tab>
              ))}
            </Tabs>
          )
          : (
            <Tabs>
              <Tab
                label={`LuxXPrime (${data && data.luxXPrime && data.luxXPrime.totalHits})`}
                onTabChange={() => onTabChange('luxXPrime')}
                selected={filters.dataType === 'luxXPrime'}
                css={data?.luxXPrime?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.luxXPrime?.totalHits === 0}
              >
                <div css={style.tabContentWrapper}>
                  <LuxXPrimeListView
                    searchResults={data}
                    data={data.luxXPrime}
                    loadingPage={loading && filters.dataType === 'luxXPrime'}
                    density={density}
                    onPaginationUpdate={onPaginationUpdate}
                    currentPageNumber={currentPageNumber}
                    totalResultSize={data?.luxXPrime?.totalHits}
                    selectedCols={selectedCols}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    sortData={sortData}
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </div>
              </Tab>
              <Tab
                key={`tab-${DataType.issuers}`}
                label={`Issuers (${data && data.issuers && data.issuers.totalHits})`}
                onTabChange={() => onTabChange('issuers')}
                selected={filters.dataType === 'issuers'}
                css={data?.issuers?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.issuers?.totalHits === 0}
              >
                <div css={style.tabContentWrapper}>
                  <IssuersListView
                    searchResults={data}
                    data={data.issuers}
                    loadingPage={loading && filters.dataType === 'issuers'}
                    density={density}
                    onPaginationUpdate={onPaginationUpdate}
                    currentPageNumber={currentPageNumber}
                    totalResultSize={data?.issuers?.totalHits}
                    selectedCols={selectedCols}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    sortData={sortData}
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </div>
              </Tab>
  
              <Tab
                label={`Securities (${data && data.securities && data.securities.totalHits})`}
                selected={filters.dataType === 'securities'}
                onTabChange={() => onTabChange('securities')}
                css={data?.securities?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.securities?.totalHits === 0}
              >
                <div css={style.tabContentWrapper}>
                  <SecuritiesListView
                    searchResults={data}
                    data={data.securities}
                    loadingPage={loading && filters.dataType === 'securities'}
                    density={density}
                    onPaginationUpdate={onPaginationUpdate}
                    currentPageNumber={currentPageNumber}
                    totalResultSize={data?.securities?.totalHits}
                    selectedCols={selectedCols}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    sortData={sortData}
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </div>
              </Tab>
  
              <Tab
                label={`Programmes (${data && data.programmes.totalHits})`}
                selected={filters.dataType === 'programmes'}
                onTabChange={() => onTabChange('programmes')}
                css={data?.programmes?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.programmes?.totalHits === 0}
              >
                <div css={style.tabContentWrapper}>
                  <ProgrammeListView
                    searchResults={data}
                    data={data.programmes}
                    loadingPage={loading && filters.dataType === 'programmes'}
                    density={density}
                    onPaginationUpdate={onPaginationUpdate}
                    currentPageNumber={currentPageNumber}
                    totalResultSize={data?.programmes?.totalHits}
                    selectedCols={selectedCols}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    sortData={sortData}
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </div>
              </Tab>
              <Tab
                label={`Documents (${data && data.documents && data.documents.totalHits})`}
                selected={filters.dataType === 'documents'}
                onTabChange={() => onTabChange('documents')}
                css={data?.documents?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.documents?.totalHits === 0}
              >
                <div css={style.tabContentWrapper}>
                  <DocumentsListView
                    searchResults={data}
                    data={data.documents}
                    loadingPage={loading && filters.dataType === 'documents'}
                    density={density}
                    onPaginationUpdate={onPaginationUpdate}
                    currentPageNumber={currentPageNumber}
                    totalResultSize={data?.documents.totalHits}
                    selectedCols={selectedCols}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    sortData={sortData}
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </div>
              </Tab>
  
              <Tab
                label={`Indices (${data && data.indexes && data.indexes.totalHits})`}
                onTabChange={() => onTabChange('indices')}
                selected={filters.dataType === 'indices'}
                css={data?.indexes?.totalHits === 0 ? { opacity: 0.5 } : {}}
                disabled={data?.indexes?.totalHits === 0}
              >
                <div css={style.tabContentWrapper}>
                  <IndexesListView
                    searchResults={data}
                    data={data.indexes}
                    loadingPage={loading && filters.dataType === 'indices'}
                    density={density}
                    onPaginationUpdate={onPaginationUpdate}
                    currentPageNumber={currentPageNumber}
                    totalResultSize={data?.indexes?.totalHits}
                    selectedCols={selectedCols}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    sortData={sortData}
                    sort={sort}
                    onSortChange={onSortChange}
                  />
                </div>
              </Tab>
            </Tabs>
  
          )
        }
      </div>
    </div>
  );
};

export default SearchResultsTabsContent;

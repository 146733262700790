import { css } from '@emotion/react';

export const style = {
filterContainer: css`
  display: flex;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  background: #F4F6F7;
  border: 1px solid #E1E3E5;
`,
container: css`
  display: flex;
  gap: 8px;
  // width: 640px;
  align-items: center;
  flex-shrink: 0;
`,

tagsWrapper: css`
  display: flex;
  gap: 8px;
    > div {
      border-radius: 255px;
      background: white;
      font-size: 14px;
      font-weight: 400;
      height: 2rem;
      inline-size: min-content;
      white-space: nowrap;
      color: #60717F;
        svg {
          color: #A4BCC2!important;}
        > div {
          color: #22AA5F;
        }
    }
`,

resetLink: css`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
    a {
        color: #425563;
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
        &:hover {
          color: #22AA5F;
        }
    }
`,
closeBTN: css`
  width: 16px;
  height: 16px;
  color: #1a2934;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
    svg {
        margin-right: 1px;
        width: 20px;
        height: 20px;
    }
`,
};
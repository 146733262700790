
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { Separator } from "../../core/components/Separator";
import { PdfLink } from "../../core/components/PdfLink";

export interface MarketOutagePlanProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const title = css`
    font-weight: bold;
`;

const marketStatusLink = css`
    text-decoration: none;
`;

export const MarketOutagePlan: React.FunctionComponent<MarketOutagePlanProps> = (props: MarketOutagePlanProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const headingValue: string = getFieldValue<string>(fields, 'heading', 'Market outage plan');
    const marketOutageLabelValue : string = getFieldValue<string>(fields, 'marketOutageLabel', 'LuxSE Market Outage plan');
    const marketOutageLinkValue = getFieldValue(fields, 'marketOutageLink', {href:""});
    const aboutTextValue : string = getFieldValue<string>(fields, 'aboutText', 'Discover detailed informations about ');
    const aboutLinkLabelValue : string = getFieldValue<string>(fields, 'aboutLinkLabel', 'Market status of Optiq trading system.');
    const aboutLinkValue = getFieldValue(fields, 'aboutLink', {href:""});

    return (
        <div css={container}>
            <h1 css={title}>{headingValue}</h1>
            <PdfLink label={marketOutageLabelValue} url={marketOutageLinkValue.href}/>
            <Separator type={"horizontal"}/>
            <p>{aboutTextValue}<a href={aboutLinkValue.href} css={marketStatusLink}>{aboutLinkLabelValue}</a></p>
        </div>
    );
}
    
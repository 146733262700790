import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { SearchInput } from "./input";
import { DataType, DataTypeContent, DataTypeKey } from "../../../enums";
import { style } from "./index.style";
import Header from "../Header/header.component";
import { MobileMenuContext } from "../../app/";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Footer from "../Footer/footer.component";
import { SearchResultsDto } from "../../../interfaces/search/search";
import { SearchResults } from "./results";
import useFilters, { DEFAULT_FILTERS } from "./useFilters";
import { Filters } from "./useFilters";
import { canUseDOM } from "../../../predicates";
import Utils from "../../utils/utils";
import { SearchType } from "../../../enums/search";
import useSearch from "../../hooks/search/useSearch";

export interface SearchContextProps {
  goToDetail: (url: string) => void;
}

export type TabValue = keyof typeof DataType;

export const SearchContext = React.createContext<Partial<SearchContextProps>>(
  {}
);

const DEFAULT_ITEMS_NUMBER = 20;

export interface SearchProps {
  goToDetail: (url: string) => void;
  testGraphqlClient?: ApolloClient<NormalizedCacheObject>;
}

export const searchPageLink = (params: string) => {
  return `/search${params ? "?" + params : ""}`;
};

export const goToSearchPage = (params: string) => {
  if (canUseDOM) window.location.href = searchPageLink(params);
};

const onSearchInputUpdate = (textInput: string) => {}; // search(textInput, itemsNumber);

export const SearchContent: React.FunctionComponent<any> = ({
  goToDetail,
  testGraphqlClient,
}: any) => {
  const mobileMenu = React.useContext(MobileMenuContext);

  const [data, setData] = useState<SearchResultsDto>(null);

  const [itemsNumber, setItemsNumber] = useState<number>(DEFAULT_ITEMS_NUMBER);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const [sort, setSort] = useState<any>({});
  const [filters, setFilters] = useFilters();
  const dataType = React.useMemo<DataTypeKey>(() => filters.dataType, [filters]);
  const searchType = React.useMemo<SearchType>(() => dataType in DataTypeContent ? SearchType.content : SearchType.data, [dataType]);
  const {isLoading:loading, search} = useSearch(searchType);

useEffect(() => {
  search(filters, itemsNumber, currentPageNumber, sort, searchType == SearchType.content).then(setData);
}, [filters, itemsNumber, currentPageNumber, sort, searchType]);

  const onTabChange = React.useCallback((dataType: TabValue) => {
    setData(null);
    setCurrentPageNumber(0);
    setSort({});
    setItemsNumber(DEFAULT_ITEMS_NUMBER);
    setFilters({
      ...DEFAULT_FILTERS,
      q: filters ? filters.q : null,
      dataType,
    });
  },[setCurrentPageNumber, setSort, setItemsNumber, setFilters, filters, DEFAULT_FILTERS]);

  const onPaginationUpdate = React.useCallback((pageNumber: number) => {
    Utils.moveToTop();
    setCurrentPageNumber(pageNumber);
  },[setCurrentPageNumber]);

  const onFiltersChange = React.useCallback((filters: Filters) => {
    setCurrentPageNumber(0);
    setFilters(filters);
  }, [setCurrentPageNumber, setFilters]);

  const onTypeSearchChange = React.useCallback((searchType: SearchType) => {
    onTabChange(searchType == SearchType.content ? 'all' : 'securities');
  }, []);

  return (
    <div css={style.search}>
      <Header
        onSwitchMenu={mobileMenu.actionDispatch}
        onSearchQueryUpdate={onSearchInputUpdate}
        routeTextInput={filters.q}
      />
      <SearchInput
        searchType={searchType}
        onSearchTypeChange={onTypeSearchChange}
        onSearchInputUpdate={onSearchInputUpdate}
        routeTextInput={filters.q}
        redirect={false}
      />
      <SearchResults
        loading={loading}
        onSearchQueryUpdate={onSearchInputUpdate}
        testGraphqlClient={testGraphqlClient}
        restData={data}
        onTabChange={onTabChange}
        currentPageNumber={currentPageNumber}
        itemsNumber={itemsNumber}
        onPaginationUpdate={onPaginationUpdate}
        filters={filters}
        onFiltersChange={onFiltersChange}
        sort={sort}
        onSortChange={setSort}
      />
      <Footer />
    </div>
  );
};

export const Search = withRouter(SearchContent);

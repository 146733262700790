import { useState, useCallback, useEffect } from 'react';
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { MarketOutage } from "../../../interfaces/market-outage";
import { GET_OUTAGE_HISTORY } from "../../../graphql/queries/market.queries";

type GraphqlResult = {
    data: {
        marketOutageHistory: MarketOutage[];
    }
}

export const useOutages = () => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [outages, setOutages] = useState<MarketOutage[]>([]);

    const getData = useCallback(async () => {
        try {
          let results: GraphqlResult = await luxseGatewayGraphqlClient.query({
            query: GET_OUTAGE_HISTORY,
          });

          setOutages(results?.data?.marketOutageHistory);
          setLoading(false);
 
        } catch (e) {
          console.error("Failed to get market outages history.", e);
          setOutages([]);
          setLoading(false);
        }
      }, [setOutages, setLoading]);

    useEffect(() => {
        setLoading(true);
        getData();
    }, []);

    return { isLoading, outages };
}
    
import * as React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { MarketOutageMessage } from "../../interfaces/market-outage";
import { MarketStatusMessage } from "./MarketStatusMessage";
import { Pagination } from "../../core/components/Pagination";

const container = css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 0;
    margin: 32px 0 0 0;
`;

const messageStyle = css`
    border: solid 1px #E1E3E5;
`;

export interface MarketOutageMessageListProps { 
    messages: MarketOutageMessage[];
    pageSize: number;
}
  
export const MarketOutageMessageList: React.FunctionComponent<MarketOutageMessageListProps> = (props: MarketOutageMessageListProps): React.ReactElement => {
    const { messages, pageSize } = props;

    const [page, setPage] = React.useState(1);
    const pageCount = React.useMemo(() => Math.ceil(messages.length/pageSize), [messages, pageSize]);

    return (
        <div css={container}>
            {messages.slice(pageSize*(page-1), pageSize*(page)).map(message => <MarketStatusMessage key={message.id} message={message} hasBorder={true}/>)}
            <Pagination currentPage={page} maxPage={pageCount} displayedPageCount={5} onPageChange={setPage}/>
        </div>
    );
}
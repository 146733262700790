import * as React from "react";

/** @jsx jsx */
import { SerializedStyles, css, jsx } from "@emotion/react";
import { MarketStatusEnum } from ".";
import { getCurrentMoment } from "../../utils/date";
import { ReactComponent as ClosedIcon } from './closed.svg';
import { ReactComponent as OpenIcon } from './open.svg';
import { ReactComponent as OutageIcon } from './outage.svg';
import { Date } from "../../core/components/Date";
import { Moment } from "moment-timezone";

export interface CurrentStatusDisplayProps { 
    status: MarketStatusEnum;
    isFullyRounded: boolean;
    date?: Moment;
}

function getIcon(status: MarketStatusEnum) {
    switch(status) {
        case MarketStatusEnum.CLOSED:
            return <ClosedIcon />;
        case MarketStatusEnum.OPEN:
            return <OpenIcon />;
        case MarketStatusEnum.MINOR_OUTAGE:
            return <OutageIcon fill="#D17600"/>;
        case MarketStatusEnum.MAJOR_OUTAGE:
            return <OutageIcon fill="#CB1234"/>;
    }
}

export const CurrentStatusDisplay: React.FunctionComponent<CurrentStatusDisplayProps> = (props: CurrentStatusDisplayProps): React.ReactElement => {
    const { status, isFullyRounded, date } = props;

    const [title, setTitle] = React.useState<string>();
    const [outageMessage, setOutageMessage] = React.useState<string>();
    const [backgroundColor, setBackgroundColor] = React.useState<string>();
    const [titleColor, setTitleColor] = React.useState<string>();
    const [currentDate] = React.useState(getCurrentMoment());

    const style: SerializedStyles = React.useMemo(() => css(`
        width: 100%;
        display: flex;
        padding: 16px;
        border-radius: ${isFullyRounded ? "4px" : "4px 4px 0 0"};
        background-color: ${backgroundColor};
        & > svg {
            margin-right: 16px;
            height: fit-content;
        }
    `), [backgroundColor, isFullyRounded]);

    React.useEffect(() => {
        switch (status) {
            case MarketStatusEnum.CLOSED:
                setTitle('Market closed')
                setOutageMessage(null);
                setBackgroundColor("#E1E3E5");
                setTitleColor("#495966");
                break;
            case MarketStatusEnum.OPEN:
                setTitle('Market open')
                setOutageMessage(null);
                setBackgroundColor("#E8F5E9");
                setTitleColor("#2B752F");
                break;
            case MarketStatusEnum.MINOR_OUTAGE:
                setTitle('Market outage')
                setOutageMessage("Minor technical issue since:");
                setBackgroundColor("#FFF1E0");
                setTitleColor("#D17600");
                break;
            case MarketStatusEnum.MAJOR_OUTAGE:
                setTitle('Market outage')
                setOutageMessage("Major technical issue since:");
                setBackgroundColor("#FEEBEE");
                setTitleColor("#CB1234");
                break;
            default:
                break;
        }
    }, [status]);

    return (
        <div css={style}>
            {getIcon(status)}
            <div>
                <b css={css`color: ${titleColor}; font-weight: bold; font-size: 20px;`}>{title}</b>
                {outageMessage && <p css={css`font-size: 13px`}>{outageMessage}</p>}
                <Date date={date ? date : currentDate}/>
            </div>
        </div>
    );
}
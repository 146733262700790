/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import SecurityHeader from './header';
import { SearchTable } from '../base';
import { SecurityRow } from './row';
import { SecurityFilters } from './security-filters';
import { SecuritySearchResultDto } from '../../../../../interfaces/search/search';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { resultCss, containerCss } from '../index.style';
import { InputSort } from '../base/sort';
import { Loader } from '../../Loader';
import SecurityEmptyResults from './empty';
import SearchResults from '../../../../../interfaces/search/search-result';
import useFilterHandlers from '../../../../hooks/search/useFilterHandlers';
import { OptionsData } from '@bdl-cmn-shared-packages-npm/design-system';
import useTagFilters from '../../../Search/results/useTagFilters';
import { FilterTags } from '../../../Search/results/filters/filter-tags';
import { CountTags } from '../../../Search/results/filters/count-tags';
import { style } from '../../../Search/results/filters/filter-tags.style';

export interface SecuritiesListViewProps {
  selectedCols: any;
  data: SecuritySearchResultDto;
  searchResults?: SearchResults;
  loadingPage?: boolean;
  onPaginationUpdate: (data: any) => void;
  currentPageNumber: number;
  density?: 'low' | 'medium' | 'high';
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
}

export const getPaddingSize = (density: 'low' | 'medium' | 'high') => {
  if (density == 'low') {
    return '24px';
  } else if (density === 'medium') {
    return '16px';
  }
  return '8px';
};

export const SecuritiesListView = ({
  data,
  searchResults,
  selectedCols,
  loadingPage,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
}: SecuritiesListViewProps) => {
  let tmpData: OptionsData[] = [];
  const tagFilters = useTagFilters(filters);
  
  const { handleResetFilter, handleResetAllFilters } = useFilterHandlers(filters, onFiltersChange, tagFilters, tmpData);
  return (
    <div css={containerCss}>
      <SecurityFilters filterCount={data && data.filters} filters={filters} onFiltersChange={onFiltersChange} />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <CountTags
          nbResults={data?.totalHits}
          filters={tagFilters.securities}
        />
        <div css={style.filterContainer}>
        <FilterTags 
           nbResults={data?.totalHits}
           filters={tagFilters.securities}
           onFilterClear={handleResetFilter}
           onReset={handleResetAllFilters}
           label="Securities"
           searchTerm={''}
           loader={loadingPage}    
        />
        <InputSort sort={sort} baseValues={sortData ? sortData['securities'] : {}} onSortChange={onSortChange} />
        </div>
        <SearchTable
          data={data?.securities}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <SecurityHeader selectedCols={selectedCols} />}
          Row={(row) => <SecurityRow key={`search-security-r-${row?.id}`} row={row} selectedCols={selectedCols} color={'rgb(53, 68, 80);'} />}
          Empty={() => <div css={{marginTop: "70px"}}><SecurityEmptyResults searchResults={searchResults}/></div>}
        />
      </div>
    </div>
  );
};
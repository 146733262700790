import { css } from '@emotion/react';

export const mobile = {
  container: css`
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    flex-direction: column;
    padding: 24px 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 25px 0px rgba(34, 41, 44, 0.15);
  `,
  thumbnail: css`
    display: flex;
    // height: 120px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
  `,
  textSection: css`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
  `,
  dateSection: css`
    display: flex;
    min-width: 160px;
    align-items: center;
    gap: 8px;
    border-left: 1px solid #E1E3E5;
  `,
  modificationDate: css`
    color: #6C7E8C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  category: css`
    display: flex;
    height: 24px;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 1px solid #E1E3E5;
    background: #FFFFFF;
  `,
  categoryIcon: css`
    width: 16px;
    height: 16px;
    fill: #22AA5F;
  `,
  categoryText: css`
    color: #60717F;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  titleSection: css`
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  `,
  title: css`
    color: #60717F;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
}
import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../base/empty.style';
import hexagon from '../../../Header/hexaBoldGrey.svg';
import { renderLink } from '../../../../../utils/helper';
import SearchResults from '../../../../../interfaces/search/search-result';

interface DocumentEmptyResultsProps {
    icon?: string;
    searchResults?: SearchResults
}

export default function DocumentEmptyResults ({ 
    icon = 'info-circle',
    searchResults
  }: DocumentEmptyResultsProps) {
    
  return (
    <div css={style.container}>
      <div css={style.hexagon}>
        <img src={renderLink(hexagon)} alt='Hexagon logo' />
        <div css={style.icon}>
          <Icon icon={icon} />
        </div>
      </div>
      <div css={style.label}>
        <p>Your search did not return any results for a document.</p>
        <p>
          Didn’t find what you were looking for? Try searching in our <a href="/search?dataType=webpage">Content section</a>
        </p>
        { searchResults?.totalHits > 0 ?
          <>
              <p>However, you can review results linked to your search in the above tabs:</p>
              <ul>
                { searchResults.luxXPrime?.totalHits > 0 ?
                  <li>LuxXPrime ({searchResults.luxXPrime.totalHits})</li>
                  : <></>
                }
                
                { searchResults.issuers?.totalHits > 0 ?
                  <li>Issuers ({searchResults.issuers.totalHits})</li>
                  : <></>
                }

                { searchResults.securities?.totalHits > 0 ?
                  <li>Securities ({searchResults.securities.totalHits})</li>
                  : <></>
                }
                
                { searchResults.programmes?.totalHits > 0 ?
                  <li>Programmes ({searchResults.programmes.totalHits})</li>
                  : <></>
                }
                
                { searchResults.indexes?.totalHits > 0 ?
                  <li>Indices ({searchResults.indexes.totalHits})</li>
                  : <></>
                }
              </ul>
          </>
          : <></>
        }
      </div>
    </div>
  );
}
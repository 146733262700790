/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import SearchResults from '../../../../../interfaces/search/search-result';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { ContentFilters } from './content-filters';
import { Loader } from '@bdl-cmn-shared-packages-npm/design-system';
import { SearchTable } from '../base';
import { containerCss, resultCss } from '../index.style';
import ContentRow from './row';
import ContentEmptyResults from './empty';
import { content } from '../content.style';

export interface ContentListViewProps {
    selectedCols: any;
    history?: any;
    data: SearchResults;
    searchResults?: SearchResults;
    loadingPage?: boolean;
    target?: string;
    onPaginationUpdate: (pageNumber: number) => void;
    currentPageNumber?: number;
    density?: 'low' | 'medium' | 'high';
    itemsNumber?: number;
    totalResultSize?: number;
    filters: SearchFilterParams;
    onFiltersChange: (filters: SearchFilterParams) => void;
    sort: any;
    sortData: any;
    onSortChange: (sort: any) => void;
    dataType: string;
  }

  export const ContentListView = ({
    data,
    searchResults,
    loadingPage,
    selectedCols,
    density,
    onPaginationUpdate,
    currentPageNumber,
    totalResultSize,
    filters,
    onFiltersChange,
    dataType
  }: ContentListViewProps) => {
    
    const getCategoryCount = (dataType: string) => {
      if (dataType === "all") {
        return data?.webPages?.totalHits || 0;
      }
      return data?.webPages?.filters?.categories?.find((category) => category.name === dataType.toLowerCase())?.count || 0;
    };

    const categoryCount = getCategoryCount(dataType);

    return (
      <div css={containerCss}>
        <ContentFilters filterCount={data?.webPages?.filters}  filters={filters} onFiltersChange={onFiltersChange} dataType={dataType} />
        <div css={content.resultCss}>
          {loadingPage && <Loader />}
          <div css={content.elementCountContainer}>
            <div css={content.elementCount}>
              <div css={content.elementCountNumber}>{categoryCount}</div>
              <div css={content.elementCountText}>
                {categoryCount === 1 ? "element found" : "elements found"}
              </div>
            </div>
          </div>
          <SearchTable
            data={data && data.webPages && data.webPages.results ? data.webPages.results : []}
            density={density}
            onPaginationUpdate={onPaginationUpdate}
            pageNumber={currentPageNumber}
            totalResultSize={totalResultSize}
            Header={() => <></> }
            Row={(row) => <ContentRow row={row} selectedCols={selectedCols} key={`search-content-r-${row?.id}`}/>}
            Empty={() => <div css={{marginTop: "70px"}}><ContentEmptyResults searchResults={searchResults}/></div>}
          />
        </div>
      </div>
    );
  };
  
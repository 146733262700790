import { useState, useCallback, useEffect } from 'react';
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { GET_CURRENT_MARKET_STATE } from "../../../graphql/queries/market.queries";
import { MarketState } from '../../../interfaces/market-state';

type GraphqlResult = {
    data: {
        currentMarketState: MarketState;
    }
}

export const useMarketState = () => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [marketState, setMarketState] = useState<MarketState>(null);

    const getData = useCallback(async () => {
        try {
          let results: GraphqlResult = await luxseGatewayGraphqlClient.query({
            query: GET_CURRENT_MARKET_STATE,
          });

          setMarketState(results?.data?.currentMarketState);
          setLoading(false);
 
        } catch (e) {
          console.error("Failed to get current market state.", e);
          setMarketState(null);
          setLoading(false);
        }
      }, [setMarketState, setLoading]);

    useEffect(() => {
        setLoading(true);
        getData()
    }, []);

    return { isLoading, marketState };
}
    
import * as React from "react";

/** @jsx jsx */
import { SerializedStyles, css, jsx } from "@emotion/react";

export interface MarketStatusMessageProps { 
    type: "vertical" | "horizontal";
    color?: string;
    borderWidth?: string;
    borderStyle?: string;
} 
  
export const Separator: React.FunctionComponent<MarketStatusMessageProps> = (props: MarketStatusMessageProps): React.ReactElement => {
    const { type, color, borderWidth, borderStyle } = props;

    const style: SerializedStyles = React.useMemo(() => css`
        border-style: ${borderStyle ? borderStyle : "solid"};
        border-color: ${color ? color : "#E1E3E5"};
        border-width: 0;
        ${type === "vertical"
            ? `border-left-width: ${borderWidth ? borderWidth : "1px"}; width: 0; height: 100%;`
            : `border-top-width: ${borderWidth ? borderWidth : "1px"}; width: 100%;  height: 0;`}
    `, [props]);

    return (
        <div css={style}></div>
    );
}
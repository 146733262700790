import SearchFilterParams from "../../../../interfaces/search/search-filter-params";
import { computeLabel as computeMarketRule, Country, Currency, DataType } from "../../../../enums";
import React, { useMemo } from "react";
import { Filters, DEFAULT_FILTERS } from "../useFilters";
import { formatDate } from "../../../../utils/date";
import { lgxEligibleCategoriesLabel } from "../../../../enums/lgx-eligible-categories";

export interface FilterTag<T> {
  name: keyof T;
  value: string;
  label: string;
}
type FilterTagsByTab = {
  [key in DataType]: FilterTag<Filters>[]
}

function defaultTagFilters(): FilterTagsByTab {
  return {
    'luxXPrime': [],
    'documents': [],
    'issuers': [],
    'programmes': [],
    'securities': [],
    'indices': [],
    'pages': [],
    'webpage': [],
    'blogpost': [],
    'pressrelease': [],
    'brochure': [],
    'form': [],
    'all': [],
  }
}

function getType(key: keyof Filters): DataType[] {
  switch (key) {
    case 'excludeRetr':
    case 'luxXPrimeOnly':
    case 'chineseBondOnly':
    case 'genderBondOnly':
    case 'marketRegulation':
    case 'securityMarketRules':
    case 'category':
    case 'currency':
    case 'tokenTypes':
    case 'tokenOnly':
    case 'lgxSustainableBonds':
    case 'lgxSustainableFunds':
    case 'lgxStandards':
    case 'lgxExternalReviewOnly':
    case 'lgxExternalReportingOnly':
    case 'lgxPostIssuanceOnly':
    case 'lgxSdgs':
    case 'lgxEligibleCategories':
    case 'lgxSlbKpiThemes':
      return [DataType.securities];

    case 'fromDate':
    case 'toDate':
    case 'sdgs':
    case 'sustainableClassification':
    case 'levelOneClassifications':
    case 'levelTwoClassifications':
    case 'couponType':
    case 'currencies':
    case 'yieldFilterFrom':
    case 'yieldFilterTo':
    case 'interestRateFilterFrom':
    case 'interestRateFilterTo':
    case 'firstTradingDateFilterFrom':
    case 'firstTradingDateFilterTo':
    case 'maturityDateFilterFrom':
    case 'maturityDateFilterTo':
    case 'includePerpetualFilter':
    case 'periodicityFilter':
    case 'eligibleProjectCategories':
    case 'countryFilter':
      return [DataType.luxXPrime];

    case 'issuerIds':
      return [DataType.luxXPrime];

    case 'issuerTypes':
    case 'issuerSubTypes':
    case 'country':
      return [DataType.issuers];

    case 'documentTypes':
    case 'documentSubTypes':
      return [DataType.documents];

    case 'programmeTypes':
    case 'programmeStartDate':
    case 'programmeEndDate':
      return [DataType.programmes];

    case 'indexTypes':
    case 'indexCurrencies':
    case 'indexLatestPriceDate':
      return [DataType.indices];

    case 'issuerType':
    case 'lgxOnly':
    case 'climateAlignedOnly':
      return [DataType.securities, DataType.issuers];

    default:
      return [];
  }
};

function getLabel(key: keyof SearchFilterParams): string {
  switch (key) {
    case 'excludeRetr': return "Include Delisted Securities";
    case 'lgxOnly': return "LGX only";
    case 'climateAlignedOnly': return "Climate aligned only";
    case 'luxXPrimeOnly': return "LuxXPrime only";
    case 'chineseBondOnly': return "Chinese bonds only";
    case 'genderBondOnly': return "Gender-focused bonds only";
    case 'tokenOnly': return "Security Tokens only";
    case 'marketRegulation': return "Market regulation";
    case 'category': return "Category";
    case 'currency': return "Currency";
    case 'country': return "Country";
    case 'issuerType': return "Issuer type";
    case 'fromDate': return "Date from";
    case 'toDate': return "Date to";
    case 'sdgs': return "SDG";
    case 'sustainableClassification': return "Eligible categories";
    case 'levelOneClassifications': return "Issuer classification 1";
    case 'levelTwoClassifications': return "Issuer classification 2";
    case 'couponType': return "coupon periodicity";
    case 'currencies': return "Currencies";
    case 'yieldFilterFrom': return "yield from";
    case 'yieldFilterTo': return "yield to";
    case 'interestRateFilterFrom': return "interest rate from";
    case 'interestRateFilterTo': return "interest rate to";
    case 'firstTradingDateFilterFrom': return "trading date from";
    case 'firstTradingDateFilterTo': return "trading date to";
    case 'maturityDateFilterFrom': return "maturity date from";
    case 'maturityDateFilterTo': return "trading date to";
    case 'includePerpetualFilter': return "include perpetual";
    case 'periodicityFilter': return "periodicity";
    case 'eligibleProjectCategories': return "Eligible categories";
    case 'countryFilter': return "countries";

    case 'programmeStartDate': return "Start date";
    case 'programmeEndDate': return "End date";

    case 'indexLatestPriceDate': return "Latest price date";

    case 'lgxExternalReviewOnly': return "External review only";
    case 'lgxPostIssuanceOnly': return "Post issuance reporting only";
  }
}

function formatRange(from: string, to?: string, prefix?: string): string {
  return (prefix ? prefix : "") + (from ? `${from}` : "/") + (to ? ` - ${to}` : "");
}

function getArrayValue( values: any ): string {
  if( values == null )
    return "";

  if( ! Array.isArray( values ) )
    return values.toString();

  values = values?.filter(v => ( v != null && v.toString().length ) );
  if( values != null && values.length)
    return values.join(",");
  else
    return "";
}

function getValue(key: keyof SearchFilterParams, filters: SearchFilterParams): string {
  switch (key) {
    case 'marketRegulation': return computeMarketRule(filters[key]);
    // case 'category': return "Category";
    case 'currency': return Currency[filters[key]];
      
    case 'yieldFilterFrom':
      return formatRange(
        filters["yieldFilterFrom"]?.toString(), 
        filters["yieldFilterTo"]?.toString(), 
        "Yield: "
      );
    case 'yieldFilterTo':
      return filters["yieldFilterFrom"] ?
        "" :  
        formatRange(
          filters["yieldFilterFrom"]?.toString(), 
          filters["yieldFilterTo"]?.toString(), 
          "Yield: "
        );

    case 'fromDate':
      return formatRange(
        formatDate(filters["fromDate"]), 
        formatDate(filters["toDate"])
      );
    case 'toDate':
      return filters["fromDate"] ?
        "" :  
        formatRange(
          formatDate(filters["fromDate"]), 
          formatDate(filters["toDate"])
      );

    case 'interestRateFilterFrom':
      return formatRange(
        filters["interestRateFilterFrom"]?.toString(), 
        filters["interestRateFilterTo"]?.toString(), 
        "Coupon: "
      );
    case 'interestRateFilterTo':
      return filters["interestRateFilterFrom"] ?
        "" :  
        formatRange(
          filters["interestRateFilterFrom"]?.toString(), 
          filters["interestRateFilterTo"]?.toString(), 
        "Coupon: ");
      
    case 'firstTradingDateFilterFrom':
      return formatRange(
        formatDate(filters["firstTradingDateFilterFrom"]), 
        formatDate(filters["firstTradingDateFilterTo"]), 
        "First trading date: "
      );
    case 'firstTradingDateFilterTo':
      return filters["firstTradingDateFilterFrom"] ?
        "" : 
        formatRange(
          formatDate(filters["firstTradingDateFilterFrom"]), 
          formatDate(filters["firstTradingDateFilterTo"]), 
          "First trading date: "
        );
    
    case 'maturityDateFilterFrom':
      return formatRange(
        formatDate(filters["maturityDateFilterFrom"]), 
        formatDate(filters["maturityDateFilterTo"]), 
        "Maturity date: "
      );
    case 'maturityDateFilterTo':
      return filters["maturityDateFilterFrom"] ?
        "" : 
        formatRange(
          formatDate(filters["maturityDateFilterFrom"]), 
          formatDate(filters["maturityDateFilterTo"]), 
          "Maturity date: "
        );
    
    case 'includePerpetualFilter': return "include perpetual";

    case 'eligibleProjectCategories':
      return filters[key].map(v => lgxEligibleCategoriesLabel(v)).join(", ");
    case 'countryFilter': 
      return filters[key].map(v => Country[v]).join(", ");

    case 'lgxOnly':
    case 'climateAlignedOnly':
    case 'chineseBondOnly': 
    case 'genderBondOnly': 
    case 'luxXPrimeOnly': 
    case 'tokenOnly': 
    case 'includePerpetualFilter':
    case 'lgxExternalReviewOnly':
    case 'lgxExternalReportingOnly': 
    case 'lgxPostIssuanceOnly': 
      return filters[key] ? getLabel(key) : "";

    case 'issuerIds':
      return filters[key] ? getArrayValue(filters[key]) : "";

    case 'excludeRetr': 
      return filters[key] ? "" : "Include Delisted Securities";
        
    case 'securityMarketRules':
    case 'lgxSustainableBonds': 
    case 'lgxSustainableFunds': 
    case 'lgxStandards': 
    case 'lgxSdgs': 
    case 'lgxEligibleCategories': 
    case 'lgxSlbKpiThemes': 
    case 'issuerTypes': 
    case 'issuerSubTypes': 
    case 'documentTypes': 
    case 'documentSubTypes': 
    case 'programmeTypes': 
    case 'sdgs':
    case 'sustainableClassification':
    case 'levelOneClassifications':
    case 'levelTwoClassifications':
    case 'couponType':
    case 'currencies':
    case 'periodicityFilter':
    case 'tokenTypes':
      return getArrayValue(filters[key]);
      
    /*
    case 'sdgs': return filters[key].map(setSDGsLabel).join(", ");
    case 'sustainableClassification': return filters[key].map(v => setSustainableClassificationLabel(v as SUSTAINABLE_CLASSIFICATIONS)).join(", ");
    case 'levelOneClassifications': return filters[key].map(v => levelOneClassificationLabels[v]).join(", ");
    case 'levelTwoClassifications': return filters[key].map(v => levelTwoClassificationLabels[v]).join(", ");
    case 'couponType': return filters[key].map(v => setCouponTypeLabel(CouponType[v])).join(", ");
    case 'currencies': return filters[key].map(v => CurrencyLong[v]).join(", ");
    case 'periodicityFilter': return filters[key].map(setPeriodicityCodeLabelDataValue).join(",");
    */

    default: return filters[key]?.toString() || "";
  }
}

export default function useTagFilters(filters: SearchFilterParams): FilterTagsByTab {

  return useMemo(() => {
    return Object.entries(filters)
      .filter(([key, value]) => Array.isArray(value) ? value.length > 0 : DEFAULT_FILTERS[key as keyof SearchFilterParams] !== value)
      .reduce((acc, [k]) => {
        const key = k as keyof SearchFilterParams;
        getType(key).forEach((dataType: DataType) => {
          acc[dataType].push({
            name: key,
            value: getValue(key, filters),
            label: getLabel(key)
          });
        })
        return acc;
      }, defaultTagFilters())
  },
    [filters])
}

export enum DataType {
  luxXPrime = "luxXPrime",
  issuers = "issuers",
  securities = "securities",
  documents = "documents",
  programmes = "programmes",
  indices = "indices",
  pages = "pages",
  webpage="webpage",
  blogpost="blogpost",
  pressrelease="pressrelease",
  brochure="brochure",
  form="form",
  all = "all",
}

export type DataTypeKey = keyof typeof DataType

export enum DataTypeContent {
  webpage="webpage",
  blogpost="blogpost",
  pressrelease="pressrelease",
  brochure="brochure",
  form="form",
  all = "all",
}

import { css } from '@emotion/react';

export const style = {
  container: css`
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-bottom: 1px solid #E1E3E5;
  `,
  count: css`
    padding: 8px 32px;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
  `,
  countNumber: css`
    color: #22AA5F;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-right: 5px;
  `,
  countText: css`
    color: #425563;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
}
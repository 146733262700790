/** @jsx jsx */
import { jsx, css } from "@emotion/react";

import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { style } from "./index.style";
import Loader from "../../Loader/loader.component";
import { SearchResultsTabsContent } from "./tabs";

import ApolloClient from "apollo-client";
import { TabValue } from "..";
import { SearchResultsDto } from "../../../../interfaces/search/search";
import SearchResultsModel from "../../../../interfaces/search/search-result";
import { useMemo } from "react";
import { Filters } from "../useFilters";
import {sharedStyle} from "../../../../styles/shared";
import {SearchResultsTabsContentMobile} from "./searchMobile";

export interface SearchResultsProps {
  restData: SearchResultsDto;
  currentPageNumber?: number;
  itemsNumber?: number;
  loading: boolean;
  onSearchQueryUpdate: (textInput: string) => void;
  onPaginationUpdate: (pageNumber: number) => void;
  onTabChange: (data: TabValue) => void;
  testGraphqlClient: ApolloClient<NormalizedCacheObject>;
  filters: Filters;
  onFiltersChange: (filters: Filters) => void
  sort: any;
  onSortChange: (sort: any) => void
}

function computeTotalHits(fields: {totalHits?: number}[]) {
  return fields.map(x => !!x ? x.totalHits : 0).reduce((acc, current) => acc + current,  0);
}

export function SearchResults(props: SearchResultsProps) {
  const {
    onSearchQueryUpdate,
    onPaginationUpdate,
    loading,
    restData,
    currentPageNumber,
    onTabChange,
    filters,
    onFiltersChange,
    sort,
    onSortChange,
  } = props;

  const data: SearchResultsModel = useMemo(() => {
    if (!restData || (!restData.luxseIssuersSearch && !restData.luxseSecuritiesSearch && !restData.luxseDocumentsSearch && !restData.luxseProgrammesSearch && !restData.luxseIndexesSearch && !restData.luxXPrimeSearch && !restData.webPagesSearch )) {
      return null;
    }
    return {
      totalHits: computeTotalHits([restData.luxseIssuersSearch
        , restData.luxseSecuritiesSearch
        , restData.luxseProgrammesSearch
        , restData.luxseDocumentsSearch
        , restData.luxXPrimeSearch
        , restData.luxseIndexesSearch
        , restData.webPagesSearch]),
      documents: restData.luxseDocumentsSearch,
      issuers: restData.luxseIssuersSearch,
      securities: restData.luxseSecuritiesSearch,
      programmes: restData.luxseProgrammesSearch,
      luxXPrime: restData.luxXPrimeSearch,
      indexes: restData.luxseIndexesSearch,
      webPages: restData.webPagesSearch
    }
  }, [restData]);

  const isLoadingFirstPage = loading && (data === null);

  return (
    <div css={isLoadingFirstPage ? style.searchLoading : {}}>
      <Loader loading={isLoadingFirstPage} maxHeight={"100px"}>
        <div css={style.container}>
          <div css={sharedStyle.hideMobile} style={{ minWidth : '100%', maxWidth : '100%' }}>
            <div css={style.searchResultsData}>
              <SearchResultsTabsContent
                  loading={loading && !isLoadingFirstPage}
                  data={data}
                  onTabChange={onTabChange}
                  currentPageNumber={currentPageNumber}
                  onPaginationUpdate={onPaginationUpdate}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  sort={sort}
                  onSortChange={onSortChange}
              />
            </div>
          </div>
          <div css={sharedStyle.hideDesktop}>
            <SearchResultsTabsContentMobile
                loading={loading && !isLoadingFirstPage}
                data={data}
                onTabChange={onTabChange}
                currentPageNumber={currentPageNumber}
                onPaginationUpdate={onPaginationUpdate}
                filters={filters}
                onFiltersChange={onFiltersChange}
                sort={sort}
                onSortChange={onSortChange}
            />
          </div>
        </div>
      </Loader>
    </div>
  );
}

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import {renderLink} from "../../utils/helper";
import {breakpointMax} from "../../style";

export interface RulesAndRegulationsProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 48px;
`;
const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 48px;

  @media (max-width: ${breakpointMax}) {
    font-size: 26px;
    line-height: 150%;
  }
  
`;
const items = css`
  display: flex;
  flex-direction: column;
`;
const item = css`
  display: flex;
  background: #F6F8F9;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 16px;
  cursor: pointer;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding: 8px;
  }
`;
const left = css`
  width: 250px;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const image = css`
  img {
    width: 100%;
    max-width: 250px;
  }
`;
const right = css`
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const itemTitle = css`
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;
const itemContent = css`
  font-size: 24px;
  line-height: 31px;
  color: #354450;
`;

export const RulesAndRegulations: React.FunctionComponent<RulesAndRegulationsProps> = (props: RulesAndRegulationsProps): React.ReactElement => {
    const { fields } = props;

    return (
        <div css={container}>
            <div css={title}>
                <Text
                    field={fields && fields.title as SitecoreGenericTextField}
                />
            </div>
            <div css={items}>
                {
                    new Array(10).fill(0).map((arr: any, index: number) => {
                        if (fields && ((fields[`item ${index + 1} download link`] as any)?.value?.src) || (fields[`item ${index + 1} link`] && (fields[`item ${index + 1} link`] as any)?.value)) {
                            return (
                                <div css={item} key={`document-${index}`} onClick={() => window.location.href = ((fields && fields[`item ${index + 1} download link`] as any).value?.src || (fields && fields[`item ${index + 1} link`] as any)?.value)}>
                                    <div css={left}>
                                        <div css={image}>
                                            <img src={renderLink(getFieldValue<any>(fields, `item ${index + 1} image`, "").src)} alt={"image"} />
                                        </div>
                                    </div>
                                    <div css={right}>
                                        <div css={itemTitle}>
                                            <Text
                                                field={fields[`item ${index + 1} title`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                        <div css={itemContent}>
                                           <Text
                                                field={fields[`item ${index + 1} description`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }})
                }
            </div>
        </div>
    );
}

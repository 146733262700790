export enum BlogPostTypeEnum {
	marketinsights = 'Market Insights',
	sustainablefinance = 'Sustainable Finance',
	fintech = 'Fintech',
	lifeatluxse = 'Life at LuxSE'
}

export function blogpostTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(BlogPostTypeEnum).indexOf(value);
  		return index >= 0 ? Object.values(BlogPostTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get BlogpostTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}

export enum BrochureTypeEnum {
	issuerservices = 'Issuer Services',
	listing = 'Listing',
	luxembourggreenexchange = 'Luxembourg Green Exchange',
	luxembourgstockexchange = 'Luxembourg Stock Exchange',
	marketdataservices = 'Market Data Services',
    stopsonbearersecurities = 'Stops On Bearer Securites',
    trading = 'Trading',
    rulesregulations = 'Rules & Regulations'
}

export function brochureTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(BrochureTypeEnum).indexOf(value);
  		return index >= 0 ? Object.values(BrochureTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get BrochureTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}

export enum FormTypeEnum {
	elisting = 'E-Listing',
	fns = 'FNS',
	letterofundertaking = 'Letter Of Undertaking',
    listingapplication = 'Listing Application',
    luxsesol = 'LuxSE SOL',
    marketabuse = 'Market Abuse',
    members = 'Members',
    mybourse = 'MyBourse',
    oam = 'OAM',
    plus = 'PLUS',
    stopsonbearersecurities = 'Stops On Bearer Securites',
}

export function formTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(FormTypeEnum).indexOf(value);
  		return index >= 0 ? Object.values(FormTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get FormTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}

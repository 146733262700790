import React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Props from '../base/props';
import { content } from '../content.style';
import { Icon, Td, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import Memo from '../../../../icons/memo';
import FileLines from "../../../../icons/file-lines";
import Bullhorn from "../../../../icons/bullhorn";
import BookOpen from "../../../../icons/book-open";
import FileInvoice from "../../../../icons/file-invoice";
import FilePdf from "../../../../icons/file-pdf";
import { LuxseSearchWebPageResultItem, LuxseSearchWebPageResults } from "../../../../../interfaces/search/search-web-pages-results";
import moment from "moment";

const lint = jsx; // linter hack

const categoryIcons: { [key: string]: JSX.Element } = {
    webpage: <FileLines style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    blogpost: <Memo style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    pressrelease: <Bullhorn style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    brochure: <BookOpen style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    form: <FileInvoice style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
};

const categoryText = {
    webpage: "Web page",
    blogpost: "Blog post",
    pressrelease: "Press release",
    brochure: "Brochure",
    form: "Form",
};

const ContentRow = ({ row }: Props<LuxseSearchWebPageResultItem>) => {

    const url = row.category === 'pressrelease' && row.url.includes('/Content/Press-center')
        ? `/about-us/press-centre/${row.id.toLowerCase().replace(/^(.{8})(.{4})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4-$5')}___${row.name}`
        : row.url;

    if (!row?.id || row.category === null)
        return <></>;

    return (
        <Tr onRowClick={() => window.open(url, "_self")} css={content.actionsRow} key={row.id}>
            <Td>
                <div css={content.resultContainer}>
                    <div css={content.thumbnail}>
                        <img src={row?.thumbnail}
                            width={"180px"}
                            height={"120px"}
                            style={{ borderRadius: "8px", objectFit: "cover" }}
                        />
                    </div>
                    <div css={content.textSection}>
                        <div css={content.title}>{row?.title}</div>
                        <div css={content.description}>{row?.description}</div>
                        {row?.modificationDate && (
                            <div css={content.modificationDate}>
                                Last update {moment(row?.modificationDate).format('DD/MM/YYYY')}
                            </div>
                        )}
                        <div css={content.category}>
                            <div css={content.categoryIcon}>
                                {categoryIcons[row?.category as keyof typeof categoryIcons] || null}
                            </div>
                            <div css={content.categoryText}>
                                {categoryText[row.category as keyof typeof categoryText] || row.category}
                            </div>
                        </div>
                    </div>
                    {row.category === "brochure" || row.category === "form" ? (
                        <div css={content.actionPdf}>
                            <FilePdf />
                        </div>
                    ) : (
                        <div css={content.action}>
                            <Icon icon={'chevron-right'} />
                        </div>
                    )}
                </div>
            </Td>
        </Tr>
    );
};

export default ContentRow
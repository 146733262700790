import { breakpoint, breakpointMax } from "../../../../style";

import { css } from "@emotion/react";

export const style = {
  searchInput: css`
    align-items: center;
    background-color: #798B99;
    display: flex;
    min-height: 70px;
    justify-content: center;
  `,
  searchInputForm: css`
    width:100%;
    @media (max-width: ${breakpointMax}) {
      background: #A4B6C2;
      padding: 10px 0px;
    }
  `,

  searchInputWrapper: css`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;

    @media (max-width: ${breakpointMax}) {
      display: flow;
    }
  `,

  searchInputField: css`
    max-width: 926px;
    width: 50%;
    position: relative;

    .css-1kkc41c .MuiOutlinedInput-root {
        min-height: 54.5px !important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: none;
        display: none;
    }

    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  searchInputButton: css`
    margin-left: 1em;
  `,
  checkboxContainer: css`
    display: flex;
    align-items: center;
    height: 54.5px;
    border-radius: 8px 0 0 8px;
    border: solid 1px #E3E6E8;
    background: #F4F6F7;
    color: #5E798D;
    font-size: 14px;
    line-height: 14px;
    padding: 0px 12px;
    border-width: inherit;

    input {
      accent-color: #22AA5F;
      height: 16px;
      width: 16px;

      .css-1tj914l {
        outline: unset !important;
      }

      :focus-visible {
        outline: unset !important;
      }

      &:focus-visible {
        outline: unset !important;
      }
    }

    @media (max-width: ${breakpointMax}) {
      height: 48px;
      padding: 16px;
      border-radius: 0px;
    }
  `,
  spanText: css`
    color: #425463;
    font-size: 14px;
    font-weight: 700;
    margin-right: 8px;
   `,

   spanTextRadio: css`
    color: #798B99;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-right: 8px;
    margin-left: 8px;
   `,

  input: (timer: boolean) => css`
    opacity: ${timer ? 1 : 0};
    transition: opacity .3s ease-in-out;
    background-color: ${timer ? "white": "rgb(164 188 194)"};
    border-radius: 0 8px 8px 0 !important;

      @media (max-width: ${breakpointMax}) {
        border-radius: 0px !important;
      }
     .css-1kkc41c .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff !important;
     }

     .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 0px;
      }

     .css-1kkc41c .MuiInputBase-input {
        color: #A4B6C2;
        font-size: 14px;
     }
      
     .jss1 {
        border-radius: 0 8px 8px 0 !important;
        border-width: 0px !important;
     }
      
     .jss4 {
        max-width: 0px;
     }
  `,
  label: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
      @media (max-width: ${breakpointMax}) {
      }
  `,
};

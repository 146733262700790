/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { formatToThousandsSeparator } from "../../../../../utils";
import { FilterTag } from "../useTagFilters";
import { style } from "./count-tags.style";

export interface CountTagsProps<T> {
    nbResults: number;
    filters: FilterTag<T>[];
}

export function CountTags<T>({ nbResults, filters }: CountTagsProps<T>) {
    const filterLang: any = filters.find((x: any) => x.name === 'lang');

    return (
        <div css={style.container}>
            <div css={style.count}>
                <div css={style.countNumber}>{formatToThousandsSeparator(nbResults)}</div>
                &nbsp;
                <div css={style.countText}>{filterLang?.value === 'FR' ? 'résultat' : 'element'}
                    {nbResults > 1 && 's'}
                    <>
                        &nbsp;{filterLang?.value === 'FR' ? 'trouvé pour' : 'found'} &nbsp;
                    </>
                </div>
            </div>
        </div>
    );
}
import * as React from "react";

/** @jsx jsx */
import { SerializedStyles, css, jsx } from "@emotion/react";
import { Moment } from "moment-timezone";

export interface DateProps {
    date: Moment;
    css?: SerializedStyles
}
  
export const Date: React.FunctionComponent<DateProps> = (props: DateProps): React.ReactElement => {
    const style = React.useMemo(() => css`
        display: block;
        font-height: 16px;
        font-weight: bold;
        margin: 0;
        ${props.css}
    `, [props.css]);

    return (
        props.date && <b css={style}>{props.date.format("DD/MM/YYYY [|] HH:mm:ss [CET]")}</b>
    );
}
    
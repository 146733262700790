import * as React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import Loader from "../../core/components/Loader/loader.component";
import { MarketStatusMessage } from "./MarketStatusMessage";
import { Separator } from "../../core/components/Separator";
import { CurrentStatusDisplay } from "./CurrentStatusDisplay";
import { MarketOutageMessageList } from "./MarketOutageMessageList";
import { useMarketState } from "../../core/hooks/market/useMarketState";
import { parseToMoment } from "../../utils/date";
import { Moment } from "moment-timezone";

export interface MarketStatusProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
    margin-bottom: 32px;
  `;

const statusMessages = css`
width: 100%;
    border: solid 1px #E1E3E5;
    border-top: none;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const title = css`
  font-weight: bold;
  margin-bottom: 40px;
`;

const seeAllLink = css`
    display: block;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    & > span {
        color: #495966;
        font-size: 16px;
    }
`;

export enum MarketStatusEnum {
    OPEN,
    CLOSED,
    MINOR_OUTAGE,
    MAJOR_OUTAGE
}

export const MarketStatus: React.FunctionComponent<MarketStatusProps> = (props: MarketStatusProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', 'Market status');
    const seeAllLabelValue: string = getFieldValue<string>(fields, 'seeAllLabel', 'See all messages');
    const seeAllLinkValue = getFieldValue(fields, 'seeAllLink', {href:''});
    const noRecordsMessageValue = getFieldValue(fields, 'noRecordsMessage', '');
    const maxMessageCount: number = React.useMemo(() => {
        if(fields && fields.maxMessageCount)
            return fields.maxMessageCount.value;
        return 2;
    }, [fields])
    
    const isPaginated: boolean = React.useMemo(() => fields && fields.isPaginated && fields.isPaginated.value, [fields]);

    const{ isLoading, marketState } = useMarketState();

    const displayedMessageCount = React.useMemo(
        () =>  {
            if(marketState === null || marketState.messages === null) return 0;
            return maxMessageCount < marketState.messages.length ? maxMessageCount : marketState.messages.length
        }
        , [marketState]);
    const marketStatus: MarketStatusEnum = React.useMemo(() => {
        if(marketState === null){
            return MarketStatusEnum.CLOSED;
        }
        if(marketState.outage !== null) {
            return marketState.outage.type === "MINOR" ? MarketStatusEnum.MINOR_OUTAGE : MarketStatusEnum.MAJOR_OUTAGE;
        }
        return marketState.sessionOpen ? MarketStatusEnum.OPEN : MarketStatusEnum.CLOSED;
    }, [marketState]);

    const outageDate: Moment = React.useMemo(
        () =>  {
            if(marketState === null || marketState.outage === null ||marketState.outage.dtType === null) return null;
            return parseToMoment(marketState.outage.dtType);
        }
        , [marketState]);

    return (
        <div css={container}>
            <Loader loading={isLoading}>
                <h1 css={title}>{headingValue}</h1>
                <CurrentStatusDisplay status={marketStatus} isFullyRounded={isPaginated || displayedMessageCount===0} date={outageDate}/>
                {
                    !isPaginated && displayedMessageCount > 0 && <div css={statusMessages}>
                        {marketState.messages.slice(0, displayedMessageCount)
                        .map(m => <MarketStatusMessage key={m.id} message={m}/>)}
                        <Separator type="horizontal"/>
                        <a href={seeAllLinkValue.href} css={seeAllLink}><span>{seeAllLabelValue}</span> &gt;</a>
                    </div>
                }
                {
                    isPaginated && (displayedMessageCount > 0
                    ? <MarketOutageMessageList messages={marketState.messages} pageSize={maxMessageCount}/>
                    : <p>{noRecordsMessageValue}</p>)
                }
            </Loader>
        </div>
    );
}
    
import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import SearchResults from '../../../../../interfaces/search/search-result';
import hexagon from '../../../Header/hexaBoldGrey.svg';
import { style } from '../base/empty.style';
import { renderLink } from '../../../../../utils/helper';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';

interface ContentEmptyResultsProps {
    icon?: string;
    searchResults?: SearchResults;
}

export default function ContentEmptyResults({
    icon = 'info-circle',
    searchResults
}: ContentEmptyResultsProps) {

    const categoryText = {
        all: "All",
        webpage: "Web page",
        blogpost: "Blog post",
        pressrelease: "Press release",
        brochure: "Brochure",
        form: "Form",
    };

    const urlParams = new URLSearchParams(window.location.search);
    const dataType = urlParams.get('dataType');
    const categoryTextValue = categoryText[dataType as keyof typeof categoryText];

    return (
        <div css={style.container}>
            <div css={style.hexagon}>
                <img src={renderLink(hexagon)} alt='Hexagon logo' />
                <div css={style.icon}>
                    <Icon icon={icon} />
                </div>
            </div>
            <div css={style.label}>
                <p>Your search did not return any results.</p>
                <p>
                    Didn’t find what you were looking for? Try searching in our <a href="/search">Data section</a>
                </p>
                {searchResults?.totalHits > 0 ?
                    <>
                        <p>However, you can review results linked to your search in the above tabs:</p>
                        <ul>
                            {searchResults?.totalHits > 0 ?
                                <li>{categoryTextValue} ({searchResults.totalHits})</li>
                                : <></>
                            }
                        </ul>
                    </>
                    : <></>
                }
            </div>
        </div>
    );
}
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { SearchTable } from '../base';
import { LuxXPrimeFilters } from './luxXPrime-filters';
import { SecuritySearchResultDto } from '../../../../../interfaces/search/search';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { containerCss, resultCss } from '../index.style';
import SecurityLuxXPrimeHeader from './header';
import { SecurityLuxXPrimeRow } from './row';
import { InputSort } from '../base/sort';
import { Loader } from '../../Loader';
import SearchResults from '../../../../../interfaces/search/search-result';
import LuxXPrimeEmptyResults from './empty';
import { FilterTags } from '../../../Search/results/filters/filter-tags';
import useFilterHandlers from '../../../../hooks/search/useFilterHandlers';
import { OptionsData } from '@bdl-cmn-shared-packages-npm/design-system';
import useTagFilters from '../../../Search/results/useTagFilters';
import { style } from '../../../Search/results/filters/filter-tags.style';
import { CountTags } from '../../../Search/results/filters/count-tags';

export interface LuxXPrimeListViewProps {
  selectedCols: any;
  data: SecuritySearchResultDto;
  searchResults?: SearchResults;
  loadingPage?: boolean;
  onPaginationUpdate: (data: any) => void;
  currentPageNumber: number;
  density?: 'low' | 'medium' | 'high';
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
}

export const getPaddingSize = (density: 'low' | 'medium' | 'high') => {
  if (density == 'low') {
    return '24px';
  } else if (density === 'medium') {
    return '16px';
  }
  return '8px';
};

export const LuxXPrimeListView = ({
  data,
  searchResults,
  selectedCols,
  loadingPage,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
}: LuxXPrimeListViewProps) => {
  let tmpNames = "";
let tmpData: OptionsData[] = [];
// let filters : Filters;
const tagFilters = useTagFilters(filters);
// let onFiltersChange: (filters: Filters) => void;

const filterContainer = css`
    margin-top: 20px;
  `;

const { handleResetFilter, handleResetAllFilters } = useFilterHandlers(filters, onFiltersChange, tagFilters, tmpData);
  return (
    <div css={containerCss}>
      <LuxXPrimeFilters filterCount={data?.filters} filters={filters} onFiltersChange={onFiltersChange} />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <CountTags
          nbResults={data?.totalHits}
          filters={tagFilters.luxXPrime}
        />
        <div css={style.filterContainer}>
        <FilterTags
           nbResults={data?.totalHits}
           filters={tagFilters.luxXPrime}
           onFilterClear={handleResetFilter}
           onReset={handleResetAllFilters}
           label="LuxXPrime"
           searchTerm={''}
           loader={loadingPage}    
        />
        <InputSort sort={sort} baseValues={sortData ? sortData['luxXPrime'] : {}} onSortChange={onSortChange} />
        </div>
        <SearchTable
          data={data?.securities}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <SecurityLuxXPrimeHeader selectedCols={selectedCols} />}
          Row={(row) => <SecurityLuxXPrimeRow row={row} selectedCols={selectedCols} key={`search-luxxprime-r-${row?.id}`}/>}
          Empty={() => <div css={{marginTop: "70px"}}><LuxXPrimeEmptyResults searchResults={searchResults}/></div>}
        />
      </div>
    </div>
  );
};

import * as React from "react"
import { SVGProps, memo } from "react"
const Bullhorn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M448 44.2c0-36.2-44.2-53.8-69-27.5l-23.9 25.2c-47.8 50.4-113 80.7-182.4 84.5l-29.1 1.6H64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64v104c0 30.9 25.1 56 56 56h16c30.9 0 56-25.1 56-56V355.4c62.1 7.9 119.8 37 163.1 82.8l23.9 25.1c24.9 26.2 69 8.6 69-27.5V44.3zM160 352.9V456c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24V352h47.6l16.4.9zM402.2 38.7c5-5.3 13.8-1.7 13.8 5.5v391.5c0 7.2-8.8 10.8-13.8 5.5l-23.8-25.1c-41.9-44.1-95.7-74.5-154.4-87.6V151.4c58.7-13.1 112.5-43.4 154.4-87.6l23.8-25.1zM192 156.9v166.3c-5.8-.7-11.6-1.1-17.5-1.5l-29.6-1.7H64.1c-17.7 0-32-14.3-32-32v-96c0-17.7 14.3-32 32-32H144.9l29.7-1.6c5.8-.3 11.7-.8 17.5-1.5zM496 192c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16v-64c0-8.8-7.2-16-16-16z" 
    />
  </svg>
)
const Memo = memo(Bullhorn)
export default Memo

import * as React from 'react';

import { ComponentFields, ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';

/** @jsx jsx */






export interface OpeningRowComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}


const container = css`
    width: 100%;
    display: flex;
    align-items: center;
    color: #54666F;
    font-weight: 600;
    font-size: 13px;
    margin-top: -1px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
  }
`;
const containerBloc = css`
    width: 100%;
  display: flex;
  height: 40px;
  padding-left: 32px;
  border: solid 1px #E3E6E8;
  align-items: center;
    justify-content: space-between;
`;

const row = css`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
`;
const containerRow = css`
  width: 100%;
  display: flex;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  border: solid 1px #E3E6E8;
  border-radius: 8px;
`;

export const OpeningRowComponent: React.FunctionComponent<OpeningRowComponentProps> = (props: OpeningRowComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            <div css={containerBloc}>
                <div css={row}>
                    <Text
                        field={fields && fields.date as SitecoreGenericTextField}
                    />
                </div>
                <div css={row}>
                    <Text
                        field={fields && fields.reason as SitecoreGenericTextField}
                    />
                </div>
            </div>
        </div>
    );
}

import { css } from '@emotion/react';

export const content = {
  mobileDropdown: css`
    width: 100%;
    height: unset !important;

    .css-1jqq78o-placeholder {
      color: #34414C;
    }

    .css-10wo9uf-option {
      color: #000000;
    }

    .css-d7l1ni-option {
      color: #22AA5F;
    }

    svg {
      color: #22AA5F !important;
      fill: #22AA5F !important;
      height: 16px !important;
      width: 16px !important;
    }
  `,
  filterMenu: css`
  > div:first-child {
    position: relative;

    > div:first-child {
      width: -webkit-calc(100% - 0.4em);
      width: -moz-calc(100% - 0.4em);
      width: calc(100% - 0.4em);
      font-weight: 700;
    }
  cursor: pointer;

  &&.open > div:first-child > div:first-child:after {
    transform: rotate(90deg);
  }
`,
  resultCss: css`
    position: relative;
    display: block;
    min-width: 80%;
    min-height: 800px;
    width: 100%;
    background: #ffffff;
    > div > table {
      overflow-x: auto;
    }
    table {
      padding: 32px;
    }
`,
  actionsRow: css`
    color: inherit;
    text-decoration: none;
    padding: 32px;
    &:hover {
      td button {
        visibility: visible;
        opacity: 1;
      }
    }
    td button {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s ease;
    }
    transition: background-color 0.5s ease;
    td {
      border-bottom: unset;
      padding-top: 0px;
      padding-bottom: 16px;
      padding-left: 0px;
      padding-right: 0px;
      }
  `,
  resultContainer: css`
    width: 100%;
    display: flex;
    padding: 24px;
    align-content: center;
    gap: 32px;
    flex: 1 0 0;
    flex-wrap: wrap;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(34, 41, 44, 0.15);

    &:hover {
      background: #F5FFF9;
    }
  `,
  thumbnail: css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  `,
  textSection: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  `,
  modificationDate: css`
    display: flex;
    min-width: 160px;
    padding-left: 16px;
    align-items: center;
    gap: 8px;
    border-left: 1px solid #E1E3E5;
    color: #6C7E8C;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  `,
  category: css`
    display: flex;
    height: 24px;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 1px solid #E1E3E5;
    background: #FFFFFF;
    margin-top: 10px;
  `,
  categoryText: css`
    color: #60717F;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  categoryIcon: css`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    fill: #22AA5F;
    margin-top: 3px;
  `,
  title: css`
    color: #60717F;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0px;
  `,
  description: css`
    color: #6C7E8C;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  `,
  action: css`
    display: flex;
    widht: 122px;
    padding: 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    fill: #22AA5F;

    svg {
      fill: #22AA5F;
      color: #22AA5F;
    }
  `,

  actionPdf: css`
    display: flex;
    widht: 122px;
    padding: 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    fill: #22AA5F;

    svg {
      height: 24px;
      width: 24px;
      fill: #22AA5F;
      color: #22AA5F;
    }
  `,

  elementCountContainer: css`
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-bottom: 1px solid #E1E3E5;
  `,

  elementCount: css`
    padding: 8px 32px;
    align-items: flex-start;
    align-self: stretch;
    display: flex;
  `,

  elementCountNumber: css`
    color: #22AA5F;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-right: 5px;
  `,

  elementCountText: css`
    color: #425563;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
}




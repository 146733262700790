
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import{ReactComponent as SimpleArrow} from './simpleArrow.svg';
import{ReactComponent as DoubleArrow} from './doubleArrow.svg';

export interface PaginationProps { 
    currentPage: number;
    maxPage: number;
    displayedPageCount: number;
    onPageChange: (newPage: number) => void;
}

const style = css`
    display: flex;
    gap: 6px;
    margin-top: 24px;
    & > * {
        display: block;
        padding: 8px;
        cursor: pointer;
        height: 32px;
        width: 32px;
        text-align: center;
        border-radius: 4px;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;

        &:hover {
            color: #22AA5F;
            fill: #22AA5F;
        }
    }
`;

const currentStyle = css`
    background: #22AA5F;
    color: #FFFFFF;
    &:hover {
        color: #FFFFFF;
    }
`;

const rotatedStyle = css`
    transform: rotate(0.5turn);
`;

export const Pagination: React.FunctionComponent<PaginationProps> = (props: PaginationProps): React.ReactElement => {
    const { currentPage, maxPage, displayedPageCount, onPageChange } = props;

    const displayedPages = React.useMemo(
        () => {
            const length = displayedPageCount > maxPage ? maxPage : displayedPageCount;
            let start = currentPage - (displayedPageCount-1)/2;
            if(start<1) {
                start = 1;
            }
            else if(start+length>maxPage) {
                start -= start+length-maxPage-1;
            }
            return Array.from({length: length},(_,index) => <div key={index+start} role="button" css={currentPage===index+start && currentStyle} onClick={() => onPageChange(index+start)}>{index+start}</div>)
        },
        [currentPage, maxPage, displayedPageCount, onPageChange]);

    return <div css={style}>
        <DoubleArrow key="first" css={rotatedStyle} onClick={() => onPageChange(1)}/>
        <SimpleArrow key="previous" css={rotatedStyle} onClick={() => currentPage>1 && onPageChange(currentPage-1)}/>
        {displayedPages}
        <SimpleArrow key="next" onClick={() => currentPage<maxPage && onPageChange(currentPage+1)}/>
        <DoubleArrow key="last" onClick={() => onPageChange(maxPage)}/>
    </div>
}
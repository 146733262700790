/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
} from "@sitecore-jss/sitecore-jss-react";
import { canUseDOM } from "../../predicates";
import { breakpointMax } from "../../style";

export interface BlogTableOfContentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: calc((100% - 780px) / 2 - 64px);
  min-width: 180px;
  position: absolute;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const sticky = css`
  padding: 32px 24px;
  background: #f4f6f7;
  border-radius: 8px;
  position: sticky;
  top: 20px;
  width: 100%;
  max-width: 310px;
`;
const title = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #354450;
  margin-bottom: 16px;
`;
const hContainer = (isSelected = false) => css`
  width: 100%;
  display: flex;
  font-weight: ${isSelected ? "700" : "600"};
  font-size: 12px;
  line-height: 15px;
  color: #425563;
  margin-bottom: 16px;
  cursor: pointer;
`;
const indicator = css`
  margin-right: 16px;
  font-size: 24px;
`;

export const BlogTableOfContent: React.FunctionComponent<BlogTableOfContentProps> =
  (props: BlogTableOfContentProps): React.ReactElement => {
    const [htmlTags, setHtmlTags] = React.useState<any>([]);
    const [selected, setSelected] = React.useState<string>("");
    const [totalHeight, setTotalHeight] = React.useState<number>(1000);

    const subContainer = css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: ${totalHeight}px;
    `;

    React.useEffect(() => {
      const rootArray: any = [];
      const articles: any =
        (canUseDOM && document.getElementsByTagName("article")) || [];
      let idx = 1;
      Array.from(articles).forEach((elem: any) => {
        const h2Array: any =
          (canUseDOM && elem.getElementsByTagName("h2")) || [];
        const h3Array: any =
          (canUseDOM && elem.getElementsByTagName("h3")) || [];
        const h4Array: any =
          (canUseDOM && elem.getElementsByTagName("h4")) || [];
        if (
          Array.from(h2Array).length +
            Array.from(h3Array).length +
            Array.from(h4Array).length >
          0
        ) {
          rootArray.push({
            section: idx,
            data: [
              Array.from(h2Array),
              Array.from(h3Array),
              Array.from(h4Array),
            ],
          });
          idx++;
        }
      });
      setHtmlTags(rootArray);

      const scrollHeight: any =
        canUseDOM &&
        Math.max(
          document.getElementById("article-container").scrollHeight,
          document.getElementById("article-container").offsetHeight,
          document.getElementById("article-container").clientHeight
        ) - 220;

      setTotalHeight(scrollHeight);
    }, []);

    return (
      <div css={container}>
        <div css={subContainer}>
          <div css={sticky}>
            <div css={title}>Table of content</div>
            {htmlTags &&
              htmlTags.map((tag: any, index: number) => (
                <section key={`section=${index}`}>
                  {tag.data.map((child: any, h3Index: number) => (
                    <div key={`child-${index}-${tag.section}-${h3Index}`}>
                      {child.map((elem: any, h4Index: number) => (
                        <div
                          key={`${elem?.innerHTML}-${tag.section}-${h4Index}`}
                          css={hContainer(
                            selected === `${index}${h3Index}${h4Index}`
                          )}
                          onClick={() => {
                            setSelected(`${index}${h3Index}${h4Index}`);
                            canUseDOM &&
                              window.scrollBy({
                                top: elem?.offsetTop - window.scrollY,
                                left: 0,
                                behavior: "smooth",
                              });
                          }}
                        >
                          <div css={indicator}>
                            {/*tag.section}.
                                                              {h3Index > 0 ? `${h3Index}.` : ''}
                                                              {`${h4Index > 0 ? `${h4Index}.` : ''}`*/}
                            •
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: elem.innerHTML }} />
                        </div>
                      ))}
                    </div>
                  ))}
                </section>
              ))}
          </div>
        </div>
      </div>
    );
  };

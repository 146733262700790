/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import React, { useState } from 'react';
import { style } from "../../../Search/results/filters/index.style";
import { Icon, Menu, MenuWithChild } from '@bdl-cmn-shared-packages-npm/design-system';
import CountItem from '../../../Search/results/filters/count-item';
import { blogpostTypeLabel, brochureTypeLabel, formTypeLabel } from '../../../../../enums/content-categories';
import { LuxseSearchWebPageFilters } from '../../../../../interfaces/search/search-web-pages-results';
import Select from "react-select";
import { content } from '../content.style';

export interface ContentFiltersProps {
    filterCount?: LuxseSearchWebPageFilters;
    filters: SearchFilterParams;
    onFiltersChange: (filters: SearchFilterParams) => void;
    fullWidth?: boolean;
    dataType: string;
}

export function ContentFilters({
    filterCount,
    filters,
    onFiltersChange,
    fullWidth,
    dataType,
  }: ContentFiltersProps) {
    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      let found = false;
      let parent = event.currentTarget;
      let head = parent.firstChild.firstChild;
      let target = event.target as Element;
  
      do {
        if (head === target) found = true;
  
        target = target.parentElement;
      } while (!found && parent !== target && target !== null);
  
      if (found) parent.classList.toggle("open");
};
const handleFilterChange = (
  key: keyof SearchFilterParams,
  value: any
): void => {
  let newFilters: SearchFilterParams = { ...filters };

  if (key === "subCategories" && value.length === 0) {
    newFilters.subCategories = [];
    return;
  } 

  if (newFilters[key] instanceof Array && filters[key] instanceof Array) {
    try {
      // @ts-ignore
      let array: any[] = filters[key] != null ? Object.assign([], filters[key]) : [];
      if (array != null && array.includes(value)) {
        array = array.filter((v) => {
          return v !== value;
        });
      } else {
        array.push(value);
      }
      array = array.filter((v) => v);
      if (array == null || !array.length) {
        delete newFilters[key];
      }
      else {
        // @ts-ignore
        newFilters[key] = array;
      }
    } catch (e) {
      console.error(
        "Failed to update filter[" + key + "]",
        filters[key],
        " with value",
        value,
        e
      );
    }
  } else {
    if (filters[key] === value) {
      delete newFilters[key];
    } else {
      // @ts-ignore
      newFilters[key] = value;
    }
  }
  onFiltersChange(newFilters);
};

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth
          ? style.searchResultsFiltersFullWidth
          : style.searchResultsFilters,
        css`
          position: relative;
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? "0" : "-17%"};
          padding-right: ${open ? "16px" : "60px"};
        `,
      ]}
    >

      {dataType === 'blogpost' && filterCount.subCategories && (
        <div css={[style.row, content.filterMenu]} onClick={handleFilterMenuClick}>
          <MenuWithChild icon="newspaper" label="Blog" width="100%" expanded={true} arrows={false}>
            <div css={[style.subRow, content.filterMenu]} onClick={handleFilterMenuClick}>
              <MenuWithChild icon="" label="" width="100%;" expanded={true} arrows={false}>
              {filterCount.subCategories.length > 1 ? (
                <div css={style.menuItem} key="all">
                  <Menu
                    width="100%;"
                    selected={!filters.subCategories || filters.subCategories.length === filterCount.subCategories.length}
                    onSelect={() => handleFilterChange("subCategories", [])}
                    childLabel={(<CountItem name="All" count={filterCount.categories.reduce((a: any, b: any) => a + b.count, 0)}
                    />) as unknown as string}
                  />
                </div>
                ) : <div></div>}
                {filterCount.subCategories &&
                  filterCount.subCategories
                    .sort((first: any, second: any) => second.count - first.count)
                    .map((item: any) => {
                      return (
                        <div css={style.menuItem} key={item.name}>
                          <Menu
                            width="100%;"
                            selected={filters.subCategories && filters.subCategories.includes(item.name)}
                            onSelect={() => handleFilterChange("subCategories", item.name)}
                            childLabel={
                              (<CountItem
                                name={blogpostTypeLabel(item.name)}
                                count={item.count} />) as unknown as string
                            }
                          />
                        </div>
                      );
                    })}
              </MenuWithChild>
            </div>
          </MenuWithChild>
        </div>
      )}
      {dataType === 'pressrelease' && filterCount.subCategories && (
        <div css={[style.row, content.filterMenu]}>
          <MenuWithChild icon="newspaper" label="Press Releases" width="100%" expanded={true} arrows={false}>
            <div css={[style.subRow, content.filterMenu]} onClick={handleFilterMenuClick}>
              <MenuWithChild icon="" label="" width="100%;" expanded={true} arrows={false}>
              {filterCount.subCategories ? (
                <div css={content.mobileDropdown}>
                  Year:
                  <Select
                    isClearable={false}
                    isSearchable={false}
                    options={[
                      ...filterCount.subCategories
                        .sort((first: any, second: any) => parseInt(second.name) - parseInt(first.name))
                        .map((item: any) => ({
                          label: item.name,
                          value: item.name,
                        })),
                    ]}
                    onChange={(item) => handleFilterChange("subCategories", item.value)}
                    placeholder="All"
                    value={
                      filterCount.subCategories.length > 1
                        ? { label: "All", value: "" }
                        : { label: filterCount.subCategories.find((subCategory: any) => subCategory.name === filters.subCategories)?.name, value: filterCount.subCategories.length }
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        marginTop: "5px",
                        color: "black",
                        borderRadius: "0",
                        borderColor: state.isFocused ? "#22AA60" : "#b3b3b3",
                        height: 24,
                        boxShadow: state.isFocused ? "0 0 0 1px #22AA60" : "none",
                        "&:hover": {
                          borderColor: "#22AA60",
                          boxShadow: "0 0 0 1px #22AA60",
                        },
                        "&:focus": {
                          borderColor: "#22AA60",
                          boxShadow: "0 0 0 1px #22AA60",
                        },
                      }),
                      indicatorSeparator: (baseStyles) => ({
                        ...baseStyles,
                        display: "none",
                      }),
                      dropdownIndicator: (baseStyles) => ({
                        ...baseStyles,
                        color: "#22AA60 !important",
                        "&:hover": {
                          color: "#22AA60",
                        },
                      }),
                    }}
                  />
                </div>
                ) : <div></div>}
              </MenuWithChild>
            </div>
          </MenuWithChild>
        </div>
      )}
      {dataType === 'brochure' && filterCount.subCategories && (
        <div css={[style.row, content.filterMenu]} onClick={handleFilterMenuClick}>
          <MenuWithChild icon="newspaper" label="Brochures" width="100%" expanded={true} arrows={false}>
            <div css={[style.subRow, content.filterMenu]} onClick={handleFilterMenuClick}>
              <MenuWithChild icon="" label="" width="100%;" expanded={true} arrows={false}>
                {filterCount.subCategories.length > 1 ? (
                <div css={style.menuItem} key="all">
                  <Menu
                    width="100%;"
                    selected={!filters.subCategories || filters.subCategories.length === filterCount.subCategories.length}
                    onSelect={() => handleFilterChange("subCategories", [])}
                    childLabel={(<CountItem name="All" count={filterCount.categories.reduce((a: any, b: any) => a + b.count, 0)}
                    />) as unknown as string}
                  />
                </div>
                ) : <div></div>}
                {filterCount.subCategories &&
                  filterCount.subCategories
                    .sort((first: any, second: any) => second.count - first.count)
                    .map((item: any) => {
                      return (
                        <div css={style.menuItem} key={item.name}>
                          <Menu
                            width="100%;"
                            selected={filters.subCategories && filters.subCategories.includes(item.name)}
                            onSelect={() => handleFilterChange("subCategories", item.name)}
                            childLabel={
                              (<CountItem
                                name={brochureTypeLabel(item.name)}
                                count={item.count} />) as unknown as string
                            }
                          />
                        </div>
                      );
                    })}
              </MenuWithChild>
            </div>
          </MenuWithChild>
        </div>
      )}
      {dataType === 'form' && filterCount.subCategories && (
        <div css={[style.row, content.filterMenu]} onClick={handleFilterMenuClick}>
          <MenuWithChild icon="newspaper" label="Forms" width="100%" expanded={true} arrows={false}>
            <div css={[style.subRow, content.filterMenu]} onClick={handleFilterMenuClick}>
              <MenuWithChild icon="" label="" width="100%;" expanded={true} arrows={false}>
              {filterCount.subCategories.length > 1 ? (
                <div css={style.menuItem} key="all">
                  <Menu
                    width="100%;"
                    selected={!filters.subCategories || filters.subCategories.length === filterCount.subCategories.length}
                    onSelect={() => handleFilterChange("subCategories", [])}
                    childLabel={(<CountItem name="All" count={filterCount.categories.reduce((a: any, b: any) => a + b.count, 0)}
                    />) as unknown as string}
                  />
                </div>
              ) : <div></div>}
                {filterCount.subCategories &&
                  filterCount.subCategories
                    .sort((first: any, second: any) => second.count - first.count)
                    .map((item: any) => {
                      return (
                        <div css={style.menuItem} key={item.name}>
                          <Menu
                            width="100%;"
                            selected={filters.subCategories && filters.subCategories.includes(item.name)}
                            onSelect={() => handleFilterChange("subCategories", item.name)}
                            childLabel={
                              (<CountItem
                                name={formTypeLabel(item.name)}
                                count={item.count} />) as unknown as string
                            }
                          />
                        </div>
                      );
                    })}
              </MenuWithChild>
            </div>
          </MenuWithChild>
        </div>
      )}
    </div>
  );
}


/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';

import { MarketOutage } from "../../interfaces/market-outage";
import { OutageReportLink } from "./OutageReportLink";
import {ReactComponent as AngleUpIcon} from "./angle-up.svg";

export interface OutageReportLinkProps {
    year: string;
    outagesPerDate: Map<string, MarketOutage[]>;
    isOpenByDefault?: boolean;
}

const container = css`
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
`;

const yearStyle = css`
    width:100%;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    border-bottom: solid 1px #E1E3E5;
    margin-bottom: 16px;
`;

const dateStyle = css`
    font-size: 14px;
    font-weight: bold;
`;

const dateContainerStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const content = css`
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    transform-origin: top;
    max-height: calc(100%);
    transition: transform 0.26s ease,
                max-height 0.13s ease;
`;

const hideContent = css`
    ${content}
    transform: scaleY(0);
    max-height: 0;
`;

const iconStyle = css`
    transition: transform 0.26s;
    margin-left: auto;
`;

const rotatedIconStyle = css`
    ${iconStyle}
    transform: rotate(0.5turn);
`;

export const SpecificYearOutages: React.FunctionComponent<OutageReportLinkProps> = (props: OutageReportLinkProps): React.ReactElement => {
    const { year, outagesPerDate, isOpenByDefault } = props;

    const [isContentHidden, setContentHidden] = React.useState(true);

    React.useEffect(() => isOpenByDefault && setContentHidden(false), []);

    return (
        <div key={year} css={container}>
            <div css={yearStyle}>
                <b>{year}</b>
                <AngleUpIcon css={isContentHidden ? rotatedIconStyle : iconStyle} onClick={() => setContentHidden(current => !current)}/>
            </div>
            <div css={isContentHidden? hideContent : content}>
            {Array.from(outagesPerDate, ([date, outages]) => <div css={dateContainerStyle} key={date}>
                    <b css={dateStyle}>{date}</b>
                    {(outages).map((outage: MarketOutage) => <OutageReportLink key={outage.id} outage={outage}/>)}</div>)}
            </div>
        </div>
    );
}
    
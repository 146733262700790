import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  searchOverlay: css`
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2000;
    transition: all 0.3s ease-in-out;
  `,

  header: css`
    z-index: 1;
    background: #ffffff;
    display: flex;
    margin-top: -125px;
    height: 125px;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;
    justify-content: space-between;
    font-size: 1em;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 30%) 0 2px 8px;
    transition: all 0.3s ease-in-out;
  `,
  headerLeft: css`
    img {
      max-width: 180px;
    }
  `,
  headerCenter: css`
    display: flex;
    border-radius: 8px; 
    background: #F4F6F7;
    padding: 16px;
    
    button {
      min-height: 55px !important;
    }
  `,
  headerCenterButton: css`
    margin-left: 1em;
  `,
  headerCenterAdvanced: css`
    margin-top: 15px;
    font-size: 1em;
    cursor: pointer;
    a {
      transition: all 0.5s ease;
      color: #22AA5F;
      text-decoration: none;
    }

    &:hover {
      a {
        color: #0D8E46;
      }
    }

    span {
      font-weight: 600;
      margin-left: 4px;
      text-decoration: underline;
    }
  `,
  headerRight: css``,
  headerRightAction: css`
    cursor: pointer;
    font-size: 32px;
    color: #22AA5F;
    margin-right: 16px;
  `,

  headerSuggestion: css`
    background-color: white;
    max-width: 926px;
    border-radius: 4px;
    width: 50%;
    position: absolute;
    right: 25%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      right: inherit;
    }
  `,
  spanText: css`
  color: #425463;
  font-size: 14px;
  font-weight: 700;
  margin-right: 8px;
 `,

 spanTextRadio: css`
    color: #798B99;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-right: 8px;
    margin-left: 8px;
   `,

   checkbox: css`
    display: flex;
    align-items: center;
    height: 54.5px;
    font-size: 14px;
    line-height: 14px;
    padding: 0px 12px;

    input {
      accent-color: #22AA5F;
      height: 16px;
      width: 16px;
    }

    @media (max-width: ${breakpointMax}) {
      height: 48px;
      padding: 16px;
      border-radius: 0px;
    }
   `,

   input: css`
    opacity: 1;
    transition: opacity .3s ease-in-out;
    background-color: white;
    
     .css-1kkc41c .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff !important;
     }

     .css-1kkc41c .MuiInputBase-input {
        color: #A4B6C2;
        font-size: 14px;
     }
  `,
    label: css`
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 16px;
        @media (max-width: ${breakpointMax}) {
        }
  `,
};

/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useRef, useState } from 'react';
import SearchResult from '../../../../../../interfaces/search/search-result';
import {isExperienceEditorActive, Text} from "@sitecore-jss/sitecore-jss-react";
import { Filters } from "../../../useFilters";

export interface MobileResultsProps {
  data?: SearchResult;
  dataType?: string;
  source?: any[];
  onSwitchTab?: (tab: string) => void;
  filters: Filters;
}

export const MobileResults = (props: MobileResultsProps): JSX.Element => {
  const {
    data,
    dataType,
    source,
    onSwitchTab,
    filters
  } = props;

  const tabRefs = useRef([]);
  const [selectedTab, setSelectedTab] = useState(filters?.dataType);
  const [hasScrolled, setHasScrolled] = useState(false);
  
  React.useEffect(() => {
    const selectedIndex = source.findIndex(item => item.dataType === filters?.dataType);
    if (selectedIndex !== -1 && tabRefs.current[selectedIndex] && !hasScrolled) {
      tabRefs.current[selectedIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      setHasScrolled(true);
    }
  }, [filters, source, hasScrolled]);

  const handleSwitchTab = (dataType: any, index: any) => {
    onSwitchTab(dataType);
    setSelectedTab(dataType);
    tabRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
  };

   const mainContainer = css`
    width: 100%;
    display: flex;
    max-width: 1280px;
    padding-left: ${dataType === "all" || dataType === "webpage" || dataType === "blogpost" || dataType === "pressrelease" || dataType === "brochure" || dataType === "form" ? "0px" : "40px" }
    padding-right: ${dataType === "all" || dataType === "webpage" || dataType === "blogpost" || dataType === "pressrelease" || dataType === "brochure" || dataType === "form" ? "0px" : "40px" }
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
  `;
  const tab = css`
    color: #22AA5F;
    min-width: 40%;
    min-height: 50px;
    display: flex;
    align-items: center;
  `;
  const tabHeader = css`
    padding-top: 0;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 0;
    scroll-behavior: smooth;
  `;
  const container = css`
    width: 100%;

    .timeline-component-part {
      display: ${isExperienceEditorActive() ? 'flex' : 'none'};
    }
  `;
  const contentTitleSelected = css`
    color: #22AA5F;
    font-size: 16px;
    font-weight: 700;
    background-color: #ffffff;
    padding-left: 8px;
    min-height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 2px #22AA5F;
    width: 100%;
  `;
  const titleSelected = css`
    color: #22AA5F;
    background-color: #ffffff;
    padding-left: 8px;
    min-height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 2px #22AA5F;
    width: 100%;
  `;
  const title = css`
    background-color: #ffffff;
    min-height: 57px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: #354450;
    width: 100%;
  `;
  const tabMobile = css`
    display: flex;
    position: absolute;
    right: 0;
    height: 52px;
    width: 30px!important;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(37, 54, 69, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  `;

  return (
    <div css={mainContainer}>
      <div css={container}>
        <div css={tabHeader}>
          {
            source.map((item: any, index: number) => {
              if (item.searchType === "content") {
                return (
                  <div css={tab} onClick={() => handleSwitchTab(item.dataType, index)} ref={(el) => (tabRefs.current[index] = el)} key={`loop-map-${index}`}>
                    <div css={item.dataType === filters?.dataType ? contentTitleSelected : title}>
                    {item.label}
                    </div>
                  </div>
                );
              }

              if (item.searchType === "data") {
                return (
                  <div css={tab} onClick={() => handleSwitchTab(item.dataType, index)} ref={(el) => (tabRefs.current[index] = el)} key={`loop-map-${index}`}>
                    <div css={item.dataType === dataType ? titleSelected : title}>
                    {item.label} ({(data as any)[item.data] ? (data as any)[item.data].totalHits : "0"})
                    </div>
                  </div>
                );
              }
            })
          }
          <div css={tabMobile} />
        </div>
      </div>
    </div>
  );
}


export default MobileResults;

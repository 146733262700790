import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';

export const style = {
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    display: flex;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  leftContent: css`
    max-width: 500px;
  `,
  right: css`
    display: flex;
    flex-direction: column;
    width: 30%;
  `,
  redTitle: css`
    font-weight: 600;
    color: #ca1234;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0.5em;
    @media (max-width: ${breakpointMax}) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    
    h1 {
      font-weight: 600;
      color: #ca1234;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0.5em;
      @media (max-width: ${breakpointMax}) {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
      }
    }
  `,
  title: css`
    font-size: 48px;
    line-height: 62px;
    font-weight: 800;
    color: rgb(66, 85, 99);
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
    }
  `,
  input: (timer: boolean) => css`
    opacity: ${timer ? 1 : 0};
    transition: opacity .3s ease-in-out;
    margin-top: 2em;
    display: flow;
    background-color: #F4F6F7;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 120px;

    label {
      font-family: 'Mont' !important;
    }

    .MuiInputBase-input {
      border: none !important;
      color: inherit;
      background: #ffffff;
      border-radius: 8px;
    }

    button {
      margin-left: 1em;
    }

    @media (max-width: ${breakpointMax}) {
      flex-direction: column;

      > div > div > div > div {
        &:last-of-type {
          background: #1acb69;
          color: #ffffff;
          position: relative;
          margin-left: -6px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          width: 54px;
        }
      }

      button {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  `,
  inputSearch: css`
    position: relative;
  `,
  suggestion: css`
    position: absolute;
    width: 100%;
    top: 30px;
  `,
  suggestionMobile: css`
    position: absolute;
    width: 100%;
    top: 30px;
  `,
  advanced: css`
    margin-top: 10px;
    font-size: 1em;
    color: #22AA5F;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      color: #0D8E46;
    }

    span {
      font-weight: 600;
      margin-left: 4px;
      text-decoration: underline;
    }
  `,
  hideMobile: css`
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
    width: 115%;
  `,
  hideDesktop: css`
    @media (min-width: ${breakpoint}) {
      display: none;
    }
    width: 100%;
  `,
  mobileBtn: css`
    margin-top: 16px;
    margin-bottom: 8px;

    width: 100%;

    button {
      width: 100%;
    }
  `,
  results: css`
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    margin-top: 10px;
    background: #ffffff;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    z-index: 3;
    overflow: auto;
  `,
  resultsCards: css`
    height: 100%;
    overflow: auto;
    margin-bottom: 80px;
  `,
  resultsBtn: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    left: 0;

    button {
      width: 100%;
    }
  `,

  spanText: css`
    color: #425463;
    font-size: 14px;
    font-weight: 700;
    margin-right: 8px;
 `,

 spanTextRadio: css`
    color: #798B99;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-right: 8px;
    margin-left: 8px;
   `,

  checkbox: css`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    margin-left: 5px;

    input {
      accent-color: #22AA5F;
      height: 16px;
      width: 16px;
    }

    @media (max-width: ${breakpointMax}) {
      border-radius: 0px;
    }
   `,

  searchInputForm: css`
   width:100%;
    @media (max-width: ${breakpointMax}) {
      background: #A4B6C2;
      padding: 10px 0px;
    }
 `,
  label: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
      @media (max-width: ${breakpointMax}) {
      }
  `,
}
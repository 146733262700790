import * as React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon, InputField, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from './searchOverlay.style';
import searchLogo from './searchLogo.svg';
import { useEffect, useRef, useState } from 'react';
import { Suggestion } from '../Search/input/suggestion';
import { renderLink } from '../../../utils/helper';
import { SearchType } from '../../../enums/search';
import { canUseDOM } from '../../../predicates';

export interface Row {
  label: string;
  action: () => void;
}
export interface SearchOverlayProps {
  label?: string;
  children?: any;
  rows?: Row[];
  onClose?: () => void;
  onSearch: () => void;
  textInput: string;
  onTextInputChange: (value: string) => void;
  searchType: SearchType;
  onSearchTypeChange: (type: SearchType) => void;
}

const SearchOverlay: React.FunctionComponent<SearchOverlayProps> = ({
  onClose,
  textInput = '',
  onTextInputChange,
  searchType,
  onSearchTypeChange
}: SearchOverlayProps) => {
  const parentRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [suggestionOpen, setSuggestionOpen] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var url = `/search?q=${textInput}`;
    if( canUseDOM ){
      if(searchType === SearchType.content) {
        url=`/search?dataType=all&q=${textInput}`;
        window.location.href=url;
      }
      else{
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("q", textInput);
        url = `/search?` + searchParams.toString();
      }
    }
    window.location.href = url;
    setSuggestionOpen(false);
  };

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTextInputChange(event.target.value);
    if (event.target.value.length >= 3) {
      setSuggestionOpen(true);
    } else {
      setSuggestionOpen(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementsByTagName('input') && document.getElementsByTagName('input')[1] && document.getElementsByTagName('input')[1].setAttribute('autofocus', 'true');
      document.getElementsByTagName('input') && document.getElementsByTagName('input')[1] && document.getElementsByTagName('input')[1].focus();
    }, 500);
  }, []);

  return (
    <div css={style.searchOverlay} ref={parentRef}>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: -1,
          background: visible ? 'rgb(66 85 99 / 50%)' : 'transparent',
          display: visible ? 'flex' : 'none',
        }}
        onClick={onClose}
      ></div>
      <div css={style.header} style={{ marginTop: visible ? '0' : '-125px' }}>
        <div css={style.headerLeft}>
          <img src={renderLink(searchLogo)} alt='logo bourse' />
        </div>
        <form onSubmit={formSubmitHandler}>
          <div css={style.headerCenter}>
            <div css={style.checkbox}>
              <span css={style.spanText}>Search in: </span>
              <label css={style.label}>
                <input
                  type="radio"
                  id="searchData"
                  name="searchType"
                  value={SearchType.data}
                  checked={searchType === SearchType.data}
                  onChange={() => onSearchTypeChange(SearchType.data)}
                />
                <span css={style.spanTextRadio}>Data</span>
              </label>
              <label css={style.label}>
                <input
                  type="radio"
                  id="searchContent"
                  name="searchType"
                  value={SearchType.content}
                  checked={searchType === SearchType.content}
                  onChange={() => onSearchTypeChange(SearchType.content)}
                />
                <span css={style.spanTextRadio}>Content</span>
              </label>
            </div>
            <div css={style.input}>
              <InputField
                placeholder={'Type your search...'}
                value={textInput}
                onChange={inputChangeHandler}
                width='364px'
                leftIcon="search"
              />
            </div>
            <div css={style.headerSuggestion}>
              {searchType === SearchType.data ?
                (<Suggestion open={suggestionOpen} onClose={() => setSuggestionOpen(false)} textInput={textInput} />
                ) : null}
            </div>
            <div css={style.headerCenterButton}>
              <Button label={'Search'} iconRight={'search'} />
            </div>
          </div>
        </form>
        <div css={style.headerRight}>
          <div
            css={style.headerRightAction}
            onClick={() => {
              setVisible(false);
              setTimeout(() => {
                if (onClose) {
                  onClose();
                }
              }, 1000);
            }}
          >
            <Icon icon={'times'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;

import * as React from "react"
import { SVGProps, memo } from "react"
const ArrowsRotate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M94 187.1C120.8 124.1 183.3 80 256 80c39.7 0 77.8 15.8 105.9 43.9l52.2 52.1H336v48h160V64h-48v78.1l-52.1-52.2C358.8 52.8 308.5 32 256 32 163.4 32 83.9 88.2 49.8 168.3L94 187.1zM64 369.9l52.1 52.1c37.1 37.2 87.4 58 139.9 58 92.5 0 171.8-56 206-135.9l-44.1-18.9C391 388.1 328.6 432 256 432c-39.7 0-77.8-15.8-105.9-43.9L97.9 336H176v-48H16v160h48v-78.1z" />
  </svg>
)
const Memo = memo(ArrowsRotate)
export default Memo
/* eslint-disable */
import * as React from "react";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import Utils from "../../utils/utils";
import { DataType } from "../../../enums";
import { 
        GET_SEARCH_DOCUMENTS_RESULTS,
        GET_SEARCH_INDEXES_RESULTS,
        GET_SEARCH_ISSUERS_RESULTS,
        GET_SEARCH_PAGES_RESULTS,
        GET_SEARCH_PROGRAMMES_RESULTS,
        GET_SEARCH_RESULTS,
        GET_SEARCH_SECURITIES_LUXXPRIME_RESULTS,
        GET_SEARCH_SECURITIES_RESULTS,
} from "../../../graphql";
import { Filters } from "../../components/Search/useFilters";

const getQuery = (dataTypeStr: string) => {
        try {
            const dataType: DataType = dataTypeStr as DataType;
    
            if (dataType)
                switch (dataType) {
                    case DataType.securities:
                        return GET_SEARCH_SECURITIES_RESULTS;
                    case DataType.luxXPrime:
                        return GET_SEARCH_SECURITIES_LUXXPRIME_RESULTS;
                    case DataType.issuers:
                        return GET_SEARCH_ISSUERS_RESULTS;
                    case DataType.programmes:
                        return GET_SEARCH_PROGRAMMES_RESULTS;
                    case DataType.documents:
                        return GET_SEARCH_DOCUMENTS_RESULTS;
                    case DataType.indices:
                        return GET_SEARCH_INDEXES_RESULTS;
                    case DataType.pages:
                        return GET_SEARCH_PAGES_RESULTS;
                    default:
                        console.error("Unknown search type '" + dataTypeStr + "'");
                        break;
                }
        } catch (error) {
            console.error("Error when try to get query '" + dataTypeStr + "'", error);
        }
        return GET_SEARCH_RESULTS;;
    };

const useSearchData = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const search = React.useCallback(async (filters: Filters, itemsNumber: number, currentPageNumber: number, sort) => {
        try {
            if (!isLoading) setIsLoading(true);

            const results: any = await luxseGatewayGraphqlClient.query({
                query: getQuery(filters?.dataType),
                variables: {
                    token: Utils.getCookie("Gztoken-Dsm"),
                    ...filters,
                    searchTerm: filters.q,
                    issuerIdFilter: filters.issuerIds
                        ? filters.issuerIds.map((id) => parseInt(id))
                        : null,
                    size: itemsNumber,
                    page: currentPageNumber,
                    sort: sort && sort.field ? `${sort.field},${sort.dir}` : "",
                    sortBy: sort && sort.field ? sort.field : "maturityDate",
                    sortDir: sort && sort.dir ? sort.dir : "DESC",
                    sdgs: filters.sdgs?.length ? filters.sdgs : null,
                    eligibleProjectCategories: filters.eligibleProjectCategories.length
                        ? filters.eligibleProjectCategories
                        : null,
                },
            });

            return results?.data || null;
        } catch (e) {
            console.error("Failed to search", e);
            return null;
        } finally{
            setIsLoading(false);
        }
    }, [isLoading, setIsLoading]);

    return {isLoading, search};
}

export default useSearchData;
/** @jsx jsx */
import { css,jsx } from '@emotion/react';

import { style } from './index.style';
import * as React from 'react';
import { formatToThousandsSeparator } from '../../../../../../utils';
import { FilterTag } from '../../useTagFilters';
import ConceptualBanner from '../../../../ConceptualBanner/conceptualBanner.component';
import { Icon, Link, Tag } from '@bdl-cmn-shared-packages-npm/design-system';
import Loader from '../../../../Loader/loader.component';
import { splitFilterValues } from '../../../../../hooks/search/useFilters';
import { getFilterLabel } from '../../../../../hooks/search/useFilters';

export interface TagFiltersProps<T> {
  nbResults: number;
  filters: FilterTag<T>[];
  onFilterClear: (key: keyof T, value: string) => void;
  onReset: () => void;
  label?: string;
  searchTerm: string;
  loader:boolean;
}

export function TagFilters<T>({ nbResults, filters, onFilterClear, onReset, label, searchTerm,loader }: TagFiltersProps<T>) {
  // use this filterLang for oam search
  const filterLang: any = filters.find((x: any) => x.name === 'lang');

  return (
    <ConceptualBanner
      reduceOnScroll={false}
      renderLeft={
        <div css={style.resultsWrapper}>
          <h1 css={style.header}>
            <span css={style.emphasizedText}>{formatToThousandsSeparator(nbResults)}</span>
            &nbsp; {filterLang?.value === 'FR' ? 'résultat' : 'element'}
            {nbResults > 1 && 's'}
            {searchTerm && (
              <>
                &nbsp;{filterLang?.value === 'FR' ? 'trouvé pour' : 'found'} &nbsp;
                <span css={style.emphasizedText}>"{searchTerm}"</span>
              </>
            )}
            {label && (
              <>
                &nbsp;in&nbsp;
                <span css={style.emphasizedText}>{label}</span>
              </>
            )}
          </h1>
          {<Loader loading={loader} centered css={{height:20}}>
            <div css={[style.tagsWrapper,css`flex-wrap: wrap;`]}>
              {filters?.map((filter: any) => {
                const values = splitFilterValues(filter.value);
                const tags = [];
                if (values != null && values.length)
                  for (let index = 0; index < values.length; index++) {
                    if (values[index] != null && values[index].length)
                      tags.push(
                        <Tag
                          key={filter.name.toString()}
                          label={getFilterLabel(label, filter.name.toString(), values[index])}
                          onClose={() => onFilterClear(filter.name, values[index])}
                        />
                      );
                  }
                return tags;
              })}
            </div>
          </Loader>}
          {filters?.length > 0 ? (
            <div css={style.resetLink}>
              <Link href='#' onClick={onReset}>
                <div css={style.closeBTN}>
                  <Icon icon={'plus'} />
                </div>
                {filterLang?.value === 'FR' ? 'Réinitialiser tous les filtres' : 'Reset all filters'}
              </Link>
            </div>
          ) : null}
        </div>
      }
      renderRight={() =>
        filters?.length > 0 ? (
          <div css={style.resetLink}>
            <Link href='#' onClick={onReset}>
              <div css={style.closeBTN}>
                <Icon icon={'plus'} />
              </div>
              {filterLang?.value === 'FR' ? 'Réinitialiser tous les filtres' : 'Reset all filters'}
            </Link>
          </div>
        ) : null
      }
    ></ConceptualBanner>
  );
}

import * as React from "react";

/** @jsx jsx */
import { SerializedStyles, css, jsx } from "@emotion/react";
import { MarketOutageMessage } from "../../interfaces/market-outage";
import { Date } from "../../core/components/Date";
import { formatDate, parseToMoment } from "../../utils/date";

export interface MarketStatusMessageProps { 
    message: MarketOutageMessage;
    hasBorder?: boolean;
}
  
export const MarketStatusMessage: React.FunctionComponent<MarketStatusMessageProps> = (props: MarketStatusMessageProps): React.ReactElement => {
    const { message, hasBorder } = props;
    
    const container = css`
        width: 100%;
        padding: 0;
        margin: 0;
    `;

    const borderContainer = css`
        ${container}
        border: solid 1px #E1E3E5;
        border-radius: 4px;
        padding: 12px 16px;
    `;

    const edited = css`
        font-size: 12px;
        color: #475b6b;
        font-style: italic;
    `;

    return (
        <div key={message.id} css={hasBorder ? borderContainer : container}>
            <Date date={parseToMoment(message.dtCre)} />
            {message.dtMaj && <div css={edited}>edited on {formatDate(message.dtMaj, "DD/MM/YYYY HH:mm:ss")} CET</div>}
            <div>{message.message}</div>
        </div>
    );
}
    
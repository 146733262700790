
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, Placeholder, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import {breakpointMax, breakpointMarketCapMax} from "../../style";

export interface TwoColumnsContainerProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const TwoColumnsContainer: React.FunctionComponent<TwoColumnsContainerProps> = (props: TwoColumnsContainerProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const leftMarginValue: string = getFieldValue<string>(fields, 'left-margin', '0');
    const leftGapValue: string = getFieldValue<string>(fields, 'left-gap', '0');
    const rightMarginValue: string = getFieldValue<string>(fields, 'right-margin', '0');
    const rightGapValue: string = getFieldValue<string>(fields, 'right-gap', '0');
    const middleMarginValue: string = getFieldValue<string>(fields, 'middle-margin', '16px');
    const isMobileReversed: boolean = getFieldValue<boolean>(fields, 'mobile-reversed', false);

    const container = React.useMemo(() => css`
        width: 100%;
        display: flex;
        gap: ${middleMarginValue};
        min-height: 800px;

        @media (max-width: ${breakpointMarketCapMax}) {
            flex-direction: ${isMobileReversed ? "column-reverse" : "column"};
        }
    `, [isMobileReversed, middleMarginValue]);

    const left = React.useMemo(() =>css`
        display: flex;
        flex-direction: column;
        gap: ${leftGapValue};
        flex: 2 0 520px;
        min-width: 520px;
        margin-top: ${95 - 58}px; // header - breadcrumb - padding
        height: 100%;
        padding: 16px;
        margin-left: ${leftMarginValue};

        @media (max-width: ${breakpointMarketCapMax}) {
            margin-left: 0px;
            padding: 0 56px;
            min-width: 0;
            width: 100%;
        }
    `, [leftMarginValue, leftGapValue]);

    const right = React.useMemo(() =>css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: ${rightGapValue};
        flex: 1 0 260px;
        height: 100%;
        min-width: 260px;
        max-width: 520px;
        margin-top: ${95 - 58}px; // header - breadcrumb - padding
        padding: 16px;
        margin-right: ${rightMarginValue};

        @media (max-width: ${breakpointMarketCapMax}) {
            margin-right: 0px;
            padding: 0 56px;
            min-width: 0;
            max-width: none;
            width: 100%;
        }
    `, [rightMarginValue, rightGapValue]);

    
    return (
        <div css={container}>
            <div css={left}>
                <Placeholder name='left-column-section' rendering={rendering} />
            </div>
            <div css={right}>
                <Placeholder name='right-column-section' rendering={rendering} />
            </div>
        </div>
    );
}


/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { MarketOutage } from "../../interfaces/market-outage";
import Loader from "../../core/components/Loader/loader.component";
import { Separator } from "../../core/components/Separator";
import { PdfLink } from "../../core/components/PdfLink";
import { Date } from "../../core/components/Date";
import { parseToMoment } from "../../utils/date";
import { useOutages } from "../../core/hooks/market/useOutages";

export interface MarketOutagesHistoryProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

type GraphqlResult = {
    data: {
        marketOutageHistory: MarketOutage[];
    }
}

const container = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const date = css`
    font-weight: bold;
    margin-bottom: 4px;
`;

const title = css`
    font-weight: bold;
`;

const seeAllLink = css`
    display: block;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    & > span {
        color: #495966;
        font-size: 16px;
    }
`;


export const MarketOutagesHistory: React.FunctionComponent<MarketOutagesHistoryProps> = (props: MarketOutagesHistoryProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', 'Summary of past market outages');
    const pdfLinkLabelValue: string = getFieldValue<string>(fields, 'pdfLinkLabel', 'Technical issue report');
    const seeAllLabelValue: string = getFieldValue<string>(fields, 'seeAllLabel', 'See all market outages');
    const seeAllLinkValue = getFieldValue(fields, 'seeAllLink', {href:""});

    const maxDisplayedOutages: number = React.useMemo(() => {
        if(fields && fields.maxDisplayedOutages>0)
            return fields.maxDisplayedOutages
            return 2;
        }, [fields]);

    const {isLoading, outages} = useOutages();

    return (
        <Loader loading={isLoading} >
            <div css={container}>
                {outages.length > 0 && <>
                    <h1 css={title}>{headingValue}</h1>
                    {outages.slice(0, maxDisplayedOutages).map(o => <div key={o.id}>
                        <Date date={parseToMoment(o.dtCre)}/>
                        <PdfLink label={pdfLinkLabelValue} url={o.url}/>
                    </div>)}
                    <Separator type={"horizontal"}/>
                    <a href={seeAllLinkValue.href} css={seeAllLink}><span>{seeAllLabelValue}</span> &gt;</a>
                </>}
            </div>
        </Loader>
    );
}
    
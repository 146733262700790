import { useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Country,
  CouponType,
  CurrencyLong,
  DataType,
  LGXEligibleCategories,
  PeriodicityCode,
} from "../../../enums";
import SearchFilterParams, {
  CountryFilter,
  CouponTypeFilter,
  CurrencyFilter,
} from "../../../interfaces/search/search-filter-params";
import { parseToMoment } from "../../../utils/date";

export interface Filters extends SearchFilterParams {
  q?: string;
  dataType: keyof typeof DataType;
}

type UseFilters = [Filters, (filters: Filters) => void];

export const DEFAULT_FILTERS: Filters = {
  excludeRetr: true,
  dataType: DataType.securities,
  q: "",
  sdgs: [],
  sustainableClassification: [],
  levelOneClassifications: [],
  levelTwoClassifications: [],
  couponType: [],
  currencies: [],
  yieldFilterFrom: null,
  yieldFilterTo: null,
  interestRateFilterFrom: null,
  interestRateFilterTo: null,
  firstTradingDateFilterFrom: null,
  firstTradingDateFilterTo: null,
  maturityDateFilterFrom: null,
  maturityDateFilterTo: null,
  includePerpetualFilter: false,
  periodicityFilter: [],
  eligibleProjectCategories: [],
  countryFilter: [],

  securityStatuses: ["ADMI", "NEGO", "COTE", "RETR"],
  securityStatusesOperator: "OR",
  securityExcludeStatuses: ["PUBL"],
  securityMarketRules: [],
  securityExcludeMarketRules: ["PUBL"],

  tokenOnly: false,
  tokenTypes: [],

  lgxSustainableBonds: [],
  lgxSustainableFunds: [],
  lgxStandards: [],
  lgxSdgs: [],
  lgxEligibleCategories: [],
  lgxSlbKpiThemes: [],

  issuerTypes: [],
  issuerSubTypes: [],
  issuerIds: [],

  documentTypes: [],
  documentSubTypes: [],
  excludeDocumentTypes: [],
  excludeDocumentSubTypes: ["D318"],

  programmeTypes: [],
  programmeActiveOnly: true,

  indexTypes: [],
  indexCurrencies: []
};

export const SDGS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
].map((key) => `GOAL_${key}`);

export enum SUSTAINABLE_CLASSIFICATIONS {
  "RBDL_GRBD" = "RBDL_GRBD",
  "RBDL_SOCI" = "RBDL_SOCI",
  "RBDL_SUST" = "RBDL_SUST",
}

export default function useFilters(): UseFilters {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const query = useMemo(() => {
    const filters: Filters = { ...DEFAULT_FILTERS };
    const params = new URLSearchParams(search);

    params.forEach((value: string, key: keyof Filters) => {
      switch (key) {
        case "lgxOnly":
        case "chineseBondOnly":
        case "genderBondOnly":
        case "luxXPrimeOnly":
        case "tokenOnly":
        case "excludeRetr":
        case "includePerpetualFilter":
        case "lgxExternalReviewOnly":
        case "lgxExternalReportingOnly":
        case "lgxPostIssuanceOnly":
          filters[key] = value === "true";
          break;
        case "climateAlignedOnly":
          filters[key] = value === "false";
          break;
        case "sdgs":
          filters[key] = value.split(",").filter((key) => SDGS.includes(key));
          break;
        case "sustainableClassification":
          filters[key] = value
            .split(",")
            .filter((key) =>
              Object.keys(SUSTAINABLE_CLASSIFICATIONS).includes(key)
            );
          break;
        case "couponType":
          filters[key] = value
            .split(",")
            .filter((key) =>
              Object.keys(CouponType).includes(key)
            ) as CouponTypeFilter;
          break;
        case "currencies":
          filters[key] = value
            .split(",")
            .filter((key) =>
              Object.keys(CurrencyLong).includes(key)
            ) as CurrencyFilter;
          break;
        case "countryFilter":
          filters[key] = value
            .split(",")
            .filter((key) =>
              Object.keys(Country).includes(key)
            ) as CountryFilter;
          break;
        case "levelOneClassifications":
        case "levelTwoClassifications":
          // @ts-ignore
          filters[key] = value.split(",").filter((key) => !!key);
          break;
        case "periodicityFilter":
          filters[key] = value
            .split(",")
            .filter((key) =>
              Object.keys(PeriodicityCode).includes(key)
            ) as PeriodicityCode[];
          break;

        case "eligibleProjectCategories":
          filters[key] = value
            .split(",")
            .filter((key) =>
              Object.keys(LGXEligibleCategories).includes(key)
            ) as LGXEligibleCategories[];
          break;
        case "yieldFilterFrom":
        case "yieldFilterTo":
        case "interestRateFilterFrom":
        case "interestRateFilterTo":
          // @ts-ignore
          filters[key] = Number.parseFloat(value) || null;
          break;
        case "firstTradingDateFilterFrom":
        case "firstTradingDateFilterTo":
        case "maturityDateFilterFrom":
        case "maturityDateFilterTo":
          const date = parseToMoment(value);
          filters[key] = date.isValid() ? date : null;
          break;

        case "securityMarketRules":
        case "lgxSustainableBonds":
        case "lgxSustainableFunds":
        case "lgxStandards":
        case "lgxSdgs":
        case "lgxEligibleCategories":
        case "lgxSlbKpiThemes":
        case "issuerTypes":
        case "issuerSubTypes":
        case "issuerIds":
          // @ts-ignore
          filters[key] = value.split(",") as string[];
          break;
        case "documentTypes":
        case "documentSubTypes":
        case "programmeTypes":
          filters[key] = value.split(",");
          break;

        default:
          // @ts-ignore
          filters[key] = value;
      }
    });

    return filters;
  }, [search]);

  const setQuery = useCallback((filters: Filters) => {
    const touchedFilters = Object.keys(filters).reduce(
      (acc: Filters, key: keyof Filters) => {
        if (filters[key] !== DEFAULT_FILTERS[key]) {
          // @ts-ignore
          acc[key] = filters[key];
        }
        return acc;
      },
      {} as Filters
    );
    const params = new URLSearchParams(touchedFilters as any);
    history.push({ pathname, search: params.toString() });
  }, []);

  return [query, setQuery];
}

import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { style } from "./index.style";
import { InputField } from "@bdl-cmn-shared-packages-npm/design-system";
import { withRouter } from "react-router-dom";
import { Suggestion } from "./suggestion";
import {sharedStyle} from "../../../../styles/shared";
import { canUseDOM } from "../../../../predicates";
import { SearchType } from "../../../../enums/search";
import { DataType } from "../../../../enums";

export interface SearchInputProps {
  onSearchInputUpdate: (textInput: string) => void;
  searchType: SearchType;
  onSearchTypeChange: (type: SearchType) => void;
  routeTextInput: string;
  history: any;
  dataType: string;
}

export const SearchInputContent: React.FunctionComponent<any> = ({
  searchType,
  onSearchTypeChange,
  routeTextInput,
}: SearchInputProps) => {
  const [textInput, setTextInput] = React.useState<string>(routeTextInput);
  const [suggestionOpen, setSuggestionOpen] = React.useState<boolean>(false);
  const [visibilityTimer, setVisibilityTimer] = React.useState<boolean>(false);
  const [searchingType, setSearchingType] = React.useState(SearchType.data);

  const handleSearchTypeChange = (newSearchType: SearchType) => {
    setSearchingType(newSearchType); 
    if (onSearchTypeChange) {
      onSearchTypeChange(newSearchType);
    }
  };
  
  React.useEffect(() => {
    setTextInput(routeTextInput);
    setSuggestionOpen(false);
    if (searchType !== SearchType.content) {
      handleSearchTypeChange(SearchType.data);
    }
  }, [routeTextInput, searchType]);

  React.useEffect(() => {
    if (canUseDOM) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('dataType') === 'all') {
        setSearchingType(SearchType.content);
      }
    }
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setVisibilityTimer(true);
    }, 0);
  }, []);

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value);
    if (searchingType === SearchType.data && event.target.value.length >= 3) {
      setSuggestionOpen(true);
    } else {
      setSuggestionOpen(false);
    }
  };

  const redirectToSearchEngine = () => {
    if (canUseDOM) {
      if (searchingType === SearchType.content) {
        window.location.href = `/search?dataType=all&q=${textInput}`;
      } else {
        window.location.href = `/search?q=${textInput}`;
      }
    }
  };

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    redirectToSearchEngine();
  };

  return (
      <>
        <div css={sharedStyle.hideMobile}>
          <div css={style.searchInput}>
            <form
                onSubmit={formSubmitHandler}
                css={style.searchInputForm}
                role="search"
            >
            <div css={style.searchInputWrapper}>
              <div css={style.checkboxContainer}>
                <span css={style.spanText}>Search in: </span>
                <label css={style.label}>
                  <input
                    type="radio"
                    id="searchData"
                    name="searchType"
                    value={SearchType.data}
                    checked={searchingType === SearchType.data}
                    onChange={() => handleSearchTypeChange(SearchType.data)}
                  />
                  <span css={style.spanTextRadio}>Data</span>
                </label>
                <label css={style.label}>
                  <input
                    type="radio"
                    id="searchContent"
                    name="searchType"
                    value={SearchType.content}
                    checked={searchingType === SearchType.content}
                    onChange={() => handleSearchTypeChange(SearchType.content)}
                  />
                  <span css={style.spanTextRadio}>Content</span>
                </label>
              </div>

              <div css={style.searchInputField}>
                <div css={style.input(visibilityTimer)}>
                  <InputField
                    placeholder="Type your search..."
                    onChange={inputChangeHandler}
                    value={textInput}
                    leftIcon="search"
                    style={{ paddingLeft: '30px', position: 'relative' }}
                  />
                </div>
                {searchingType === SearchType.data && suggestionOpen ?
                  (<Suggestion open={suggestionOpen}
                    onClose={() => setSuggestionOpen(false)}
                    textInput={textInput} />
                  ) : null}
              </div>
            </div>
          </form>
          </div>
        </div>
      <div css={sharedStyle.hideDesktop}>
        <form
          onSubmit={formSubmitHandler}
          css={style.searchInputForm}
          role="search"
        >
          <div css={style.searchInputWrapper}>
            <div css={style.checkboxContainer}>
              <span css={style.spanText}>Search in: </span>
              <label css={style.label}>
              <input
                type="radio"
                id="searchData"
                name="searchType"
                value={SearchType.data}
                checked={searchingType === SearchType.data}
                onChange={() => handleSearchTypeChange(SearchType.data)}
              />
              <span css={style.spanTextRadio}>Data</span>
              </label>
              <label css={style.label}>
              <input
                type="radio"
                id="searchContent"
                name="searchType"
                value={SearchType.content}
                checked={searchingType === SearchType.content}
                onChange={() => handleSearchTypeChange(SearchType.content)}
              />
              <span css={style.spanTextRadio}>Content</span>
              </label>
            </div>

            <div css={style.searchInputField}>
              <div css={style.input(visibilityTimer)}>
                <InputField
                  placeholder="Type your search..."
                  onChange={inputChangeHandler}
                  value={textInput}
                  leftIcon="search"
                  style={{ borderColor: '#ffffff !important' }}
                />
              </div>
              {searchingType === SearchType.data && suggestionOpen ?
                  (<Suggestion open={suggestionOpen}
                    onClose={() => setSuggestionOpen(false)}
                    textInput={textInput} />
                  ) : null}
            </div>
          </div>

        </form>
      </div>
      </>
  );
}

export const SearchInput = withRouter(SearchInputContent);

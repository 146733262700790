/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import Props from '../base/props';
import { mobile } from '../content.mobile.style';
import Memo from '../../../../icons/memo';
import { LuxseSearchWebPageResultItem } from '../../../../../interfaces/search/search-web-pages-results';
import moment from 'moment';
import FileLines from '../../../../icons/file-lines';
import Bullhorn from '../../../../icons/bullhorn';
import BookOpen from '../../../../icons/book-open';
import FileInvoice from '../../../../icons/file-invoice';

const categoryIcons: { [key: string]: JSX.Element } = {
    webpage: <FileLines style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    blogpost: <Memo style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    pressrelease: <Bullhorn style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    brochure: <BookOpen style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
    form: <FileInvoice style={{ color: "unset !important", fill: "#22AA5F !important", marginRight: '5px' }} />,
};

const categoryText = {
    webpage: "Web page",
    blogpost: "Blog post",
    pressrelease: "Press release",
    brochure: "Brochure",
    form: "Form",
};

export const ContentSearchCard = (props: Props<LuxseSearchWebPageResultItem>): JSX.Element => {
    const {card} = props;

    const url = card.category === 'pressrelease' && card.url.includes('/Content/Press-center')
        ? `${window.location.hostname}/about-us/press-centre/${card.id.toLowerCase().replace(/^(.{8})(.{4})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4-$5')}___${card.name}`
        : card.url;

    if (!card) return <></>

    return (
        <div css={mobile.container} onClick={() => window.open(url, '_blank')} key={card.id}>
            <div css={mobile.thumbnail}>
                <img src={card?.thumbnail}
                    width={"270px"}
                    height={"180px"}
                    style={{ borderRadius: '8px' }}
                />
            </div>
            <div css={mobile.textSection}>
                <div css={mobile.titleSection}>
                    <div css={mobile.title}>{card.title}</div>
                </div>
                <div css={mobile.dateSection}>
                    {card?.modificationDate && (
                        <div css={mobile.modificationDate}>
                            Last update {moment(card?.modificationDate).format('DD/MM/YYYY')}
                        </div>
                    )}
                </div>
                <div css={mobile.category}>
                    <div css={mobile.categoryIcon}>
                        {categoryIcons[card?.category as keyof typeof categoryIcons] || null}
                    </div>
                    <div css={mobile.categoryText}>
                        {categoryText[card.category as keyof typeof categoryText] || card.category}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentSearchCard
/* eslint-disable */
import * as React from "react";
import useSearchData from "./useSearchData";
import useSearchContent from "./useSearchContent";
import { SearchType } from "../../../enums/search";
import { Filters } from "../../components/Search/useFilters";

const useSearch = (searchType: SearchType) => {
  const {
    isLoading: isSearchDataLoading,
    search: searchData,
  } = useSearchData();
  const {
    isLoading: isContentLoading,
    search: searchContent,
  } = useSearchContent();
  const isContent = React.useMemo(
    () => SearchType.content == searchType,
    [searchType]
  );
  const isLoading = React.useMemo<boolean>(
    () => isContent ? isContentLoading : isSearchDataLoading,
    [isSearchDataLoading, isContentLoading]
  );
  const search = React.useCallback(
    (filters: Filters, itemsNumber: number, currentPageNumber: number, sort: any, isContent: boolean) => isContent
      ? searchContent(filters, itemsNumber, currentPageNumber)
      : searchData(filters, itemsNumber, currentPageNumber, sort),
    [isContent, searchContent, searchData]
  );

  return { isLoading, search };
};

export default useSearch;

import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";

/** @jsx jsx */

export interface OpeningYearComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  color: #354450;
  font-weight: 800;
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    margin-top: 48px;
    margin-bottom: 32px;
  }
  /*text-transform: lowercase;
  ::first-letter { text-transform: uppercase; }*/
`;

export const OpeningYearComponent: React.FunctionComponent<OpeningYearComponentProps> =
  (props: OpeningYearComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, "heading", "");

    return (
      <div css={container}>
        <Text field={fields && (fields.year as SitecoreGenericTextField)} />
      </div>
    );
  };

/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import IssuerHeader from './header';
import { SearchTable } from '../base';
import IssuerRow from './row';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { IssuerFilters } from './issuer-filters';
import { IssuerSearchResultDto } from '../../../../../interfaces/search/search';
import { containerCss, resultCss } from '../index.style';
import { Loader } from '../../Loader';
import { InputSort } from '../base/sort';
import SearchResults from '../../../../../interfaces/search/search-result';
import IssuerEmptyResults from './empty';
import { TagFilters } from '../../../Search/results/filters/stats';
import ConceptualBanner from '../../../ConceptualBanner/conceptualBanner.component';
import { OptionsData } from '@bdl-cmn-shared-packages-npm/design-system';
import useTagFilters from '../../../Search/results/useTagFilters';
import { style } from '../../../Search/results/filters/filter-tags.style';
import { CountTags } from '../../../Search/results/filters/count-tags';
import { FilterTags } from '../../../Search/results/filters/filter-tags';
import useFilterHandlers from '../../../../hooks/search/useFilterHandlers';

export interface SecuritiesListViewProps {
  selectedCols: any;
  history?: any;
  data: IssuerSearchResultDto;
  searchResults?: SearchResults;
  target?: string;
  onPaginationUpdate: (pageNumber: number) => void;
  currentPageNumber: number;
  density?: 'low' | 'medium' | 'high';
  itemsNumber?: number;
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
  loadingPage?: boolean;
}


export const IssuersListView = ({
  data,
  searchResults,
  selectedCols,
  sort,
  sortData,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  onSortChange,
  loadingPage,
}: SecuritiesListViewProps) => {
  let tmpData: OptionsData[] = [];
  const tagFilters = useTagFilters(filters);

  const { handleResetFilter, handleResetAllFilters } = useFilterHandlers(filters, onFiltersChange, tagFilters, tmpData);

  return (
    <div css={containerCss}>
      <IssuerFilters filterCount={data && data.filters} filters={filters} onFiltersChange={onFiltersChange} />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <CountTags
          nbResults={data?.totalHits}
          filters={tagFilters.issuers}
        />
        <div css={style.filterContainer}>
          <FilterTags
            nbResults={data?.totalHits}
            filters={tagFilters.issuers}
            onFilterClear={handleResetFilter}
            onReset={handleResetAllFilters}
            label="Issuers"
            searchTerm={''}
            loader={loadingPage}
          />
          <InputSort sort={sort} baseValues={sortData ? sortData['issuers'] : {}} onSortChange={onSortChange} />
        </div>
        <SearchTable
          data={data?.issuers}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <IssuerHeader selectedCols={selectedCols} />}
          Row={(row) => <IssuerRow row={row} selectedCols={selectedCols} key={`search-issuer-r-${row?.id}`} />}
          Empty={() => <div css={{ marginTop: "70px" }}><IssuerEmptyResults searchResults={searchResults} /></div>}
        />
      </div>
    </div>
  );
};

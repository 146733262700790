
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import filePdfSvg from "./file-pdf.svg";

export interface PdfLinkProps { 
    label: string;
    url: string;
}

const style = css`
    display: block;
    text-decoration: none;
    font-weight: 500;
    &::before {
        vertical-align: middle;
        margin-right: 8px;
        color: #22AA5F;
        content: url(${filePdfSvg});
    }
`;

export const PdfLink: React.FunctionComponent<PdfLinkProps> = (props: PdfLinkProps): React.ReactElement => {
    const { label, url} = props;
    return <a href={url} css={style}>{label}</a>
}

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';

import { MarketOutage } from "../../interfaces/market-outage";
import { parseToMoment } from "../../utils/date";
import filePdfSvg from "../../core/components/PdfLink/file-pdf.svg";
import {ReactComponent as FileExportIcon} from "./file-export.svg";

export interface OutageReportLinkProps { 
    outage: MarketOutage;
}

const container = css`
    display: flex;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    border: solid 1px #22AA5F;
    text-decoration: none;
    color: #34414C;

    &:hover {
        color: #22AA5F;
    }
`;

const style = css`
    margin: 0 16px;
    font-weight: 500;
    &::before {
        vertical-align: middle;
        margin-right: 8px;
        content: url(${filePdfSvg});
    }
`;

const fileExport = css`
    margin-left: auto;
`;

export const OutageReportLink: React.FunctionComponent<OutageReportLinkProps> = (props: OutageReportLinkProps): React.ReactElement => {
    const { outage } = props;

    return (
        <a key={outage.id} href={outage.url} css={container}>
            <div>{parseToMoment(outage.dtCre).format('HH:mm')} CET</div>
            <div css={style}>{outage.filename}</div>
            <FileExportIcon css={fileExport}/>
        </a>
    );
}
    
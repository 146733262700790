import * as React from "react"
import { SVGProps, memo } from "react"
const BookOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M156 32c44.6 0 89.7 8.6 132 22.6 42.3-14 87.4-22.6 132-22.6 55.4 0 107.2 14.6 128.9 21.6C565.7 59 576 74.5 576 91.1v311.5c0 26.9-25.1 44.8-49 40.6-18.5-3.2-51.3-7.2-99-7.2-58.9 0-97.8 24.3-111.2 34.1-7.7 5.7-17.9 9.9-29.3 9.9-10.2 0-19.6-3.4-27-8.4C245.5 461.5 202 436 148 436c-45.2 0-80.1 4.4-100 7.7-24 4-48-14.1-48-40.2V91.1C0 74.5 10.3 59 27.1 53.6 48.8 46.6 100.6 32 156 32zm124 36.8C239.9 55.8 197.6 48 156 48 103 48 53.1 62 32 68.8 22.2 72 16 81.1 16 91.1v312.5c0 15.4 14.4 26.9 29.4 24.4 20.6-3.4 56.4-8 102.6-8 58.7 0 105.4 27.5 121.4 38.3 3.2 2.1 6.8 3.8 10.6 4.8V68.9zm16 394c4.2-1.2 8-3.2 11.4-5.6 15-11.1 57.2-37.2 120.6-37.2 48.6 0 82.3 4.1 101.7 7.4 15.2 2.6 30.3-9 30.3-24.9V91c0-10-6.2-19.1-16-22.2C522.9 62 473 48 420 48c-41.6 0-83.9 7.8-124 20.8v394z" 
    />
  </svg>
)
const Memo = memo(BookOpen)
export default Memo

import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import { style } from "./index.style";

export interface OpeningAndClosingDaysProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const OpeningAndClosingDays: React.FunctionComponent<OpeningAndClosingDaysProps> =
  (props: OpeningAndClosingDaysProps): React.ReactElement => {
    const { fields } = props;

    const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);
    const captionValue: string = getFieldValue<string>(fields, 'caption', '');
    const tradingPhases = Array.from(Array(5).keys())
      .map((_, index) => (
        {
          name: getFieldValue<string>(fields, `phase${index+1}Name`, null),
          time: getFieldValue<string>(fields, `phase${index+1}Time`, null)
        }));


    return (
      <div css={style.container}>
        <div css={style.hexaLight} />
        <div css={style.halfHexa} />
        <div css={style.topContainer}>
          <div css={style.leftContainer} />
          <div css={style.title}>
            <Text
                tag={isH1 ? 'h1' : 'div'}
                field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={style.description}>
            <Text
              field={
                fields && (fields.description as SitecoreGenericTextField)
              }
            />
          </div>
          <div css={style.hoursTitle}>
            <Text
              field={
                fields && (fields.hoursTitle as SitecoreGenericTextField)
              }
            />
          </div>
          <div css={style.hoursContainer}>
            <div css={style.hoursContainerLeft}>
              <div css={style.hoursRange}>
                <Text
                  field={
                    fields &&
                    (fields.hoursRange as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={style.daysRange}>
                <Text
                  field={
                    fields &&
                    (fields.daysRange as SitecoreGenericTextField)
                  }
                />
              </div>
            </div>
            <table css={style.openingPhase}>
              <tbody>
                {tradingPhases.map(phase => <tr key={phase.name}>
                  <td>{phase.name}</td>
                  <td>{phase.time}</td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
        {captionValue && <p>{captionValue}</p>}
      </div>
    );
  };

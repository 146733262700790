/** @jsx jsx */
import { jsx } from '@emotion/react';

import DocumentHeader from './header';
import { SearchTable } from '../base';
import DocumentRow from './row';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { DocumentFilters } from './document-filters';
import { DocumentSearchResultDto } from '../../../../../interfaces/search/search';
import { containerCss, resultCss } from '../index.style';
import { InputSort } from '../base/sort';
import { Loader } from '../../Loader';
import SearchResults from '../../../../../interfaces/search/search-result';
import DocumentEmptyResults from './empty';
import useFilterHandlers from '../../../../hooks/search/useFilterHandlers';
import { OptionsData } from '@bdl-cmn-shared-packages-npm/design-system';
import useTagFilters from '../../../Search/results/useTagFilters';
import { FilterTags } from '../../../Search/results/filters/filter-tags';
import { CountTags } from '../../../Search/results/filters/count-tags';
import { style } from '../../../Search/results/filters/filter-tags.style';

export interface SecuritiesListViewProps {
  selectedCols: any;
  history?: any;
  data: DocumentSearchResultDto;
  searchResults?: SearchResults;
  target?: string;
  onPaginationUpdate: (pageNumber: number) => void;
  density?: 'low' | 'medium' | 'high';
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  currentPageNumber: number;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
  loadingPage?: boolean;
  fastLaneUrls?: string[];
}

export const DocumentsListView = ({
  data,
  searchResults,
  loadingPage,
  selectedCols,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
  fastLaneUrls,
}: SecuritiesListViewProps) => {
  let tmpData: OptionsData[] = [];
  const tagFilters = useTagFilters(filters);
  
  const { handleResetFilter, handleResetAllFilters } = useFilterHandlers(filters, onFiltersChange, tagFilters, tmpData);
  
  return (
    <div css={containerCss}>
      <DocumentFilters filterCount={data && data.filters} filters={filters} onFiltersChange={onFiltersChange} />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <CountTags
          nbResults={data?.totalHits}
          filters={tagFilters.documents}
        />
        <div css={style.filterContainer}>
          <FilterTags
            nbResults={data?.totalHits}
            filters={tagFilters.documents}
            onFilterClear={handleResetFilter}
            onReset={handleResetAllFilters}
            label="Documents"
            searchTerm={''}
            loader={loadingPage}
          />
          <InputSort sort={sort} baseValues={sortData ? sortData['documents'] : {}} onSortChange={onSortChange} />
        </div>
        <SearchTable
          data={data.documents}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <DocumentHeader selectedCols={selectedCols} />}
          Row={(data) => <DocumentRow row={data} selectedCols={selectedCols} fastLaneUrls={fastLaneUrls} key={`search-document-r-${data?.id}`} />}
          Empty={() => <div css={{ marginTop: "70px" }}><DocumentEmptyResults searchResults={searchResults} /></div>}
        />
      </div>
    </div>
  );
};

import gql from "graphql-tag";

export const GET_SEARCH_WEB_PAGE_RESULTS = gql`
query GetLuxseSearchWebPageResults(
    $searchTerm: String!,
    $page : Int,
    $size : Int,
    $sort : String,
    $categories : [String!],
    $subCategories : [String!],
    $tags : [String!],
    $authors : [String!],
    $fromDate : Date,
    $toDate : Date,
){
    luxseWebPagesSearch(
        searchTerm: $searchTerm,
        page: $page,
        size: $size,
        sort: $sort
        categories: $categories
        subCategories: $subCategories
        tags: $tags
        authors: $authors
        fromDate: $fromDate
        toDate: $toDate
    ){
        totalHits,
        results {
            id
            url
            name
            title
            subTitle
            description
            thumbnail
            category
            subCategories
            tags
            topics
            authors
            publicationDate
            modificationDate
        }
        filters{
            categories{
                name
                count
            }
            subCategories{
                name
                count
            }
            tags{
                name
                count
            }
            topics{
                name
                count
            }
            authors{
                name
                count
            }
        }
    }
}
`;


/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';
import { Table, Tbody, Pagination, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { useStyle } from './useStyle';
import { Loader } from '../../Loader';
import EmptyResults from './empty';

export interface ListViewProps<T> {
  data: T[];
  onPaginationUpdate: (pageNumber: number) => void;
  pageNumber: number;
  density?: 'low' | 'medium' | 'high';
  totalResultSize?: number;
  itemPerPage?: number;
  loadingPage?: boolean;
  Header: () => JSX.Element;
  Row: (data: T, index: number) => JSX.Element;
  Empty?: () => JSX.Element;
}

export const SearchTable = ({
  data,
  density,
  onPaginationUpdate,
  pageNumber = 0,
  totalResultSize,
  itemPerPage = 20,
  loadingPage,
  Header,
  Row,
  Empty,
}: ListViewProps<any>) => {
  const style = useStyle(density);
  const maxNumberPage = 500;
  let nbPage = 0;
  if (totalResultSize > itemPerPage)
    nbPage = Math.trunc(totalResultSize / itemPerPage) + (totalResultSize % itemPerPage > 0 ? 1 : 0);
  if (nbPage > maxNumberPage)
    nbPage = maxNumberPage;

  const [goto, setGoto] = useState(pageNumber);

  return data?.length ? (
    <div css={style.table}>
      <Table sortable>
        {Header()}
        <Tbody>{data.map(Row)}</Tbody>
      </Table>
      {loadingPage && <Loader />}

      {nbPage > 0 && (
        <div css={style.paginationContainer}>
          <div>
            <Pagination
              id={"searchDesktopPagination"}
              initialPage={pageNumber}
              onPageChange={(d: any) => {
                onPaginationUpdate(d.selected);
              }}
              total={nbPage}
            />
          </div>
          <div css={style.navigateToPage}>
            <span css={style.spanText}>Go to the page</span>
            <input
              type="number"
              value={goto}
              min="1"
              max={nbPage}
              css={style.inputBox}
              onChange={e => setGoto(parseInt(e.target.value))}>
            </input>
            <Button
              onClick={() => onPaginationUpdate(goto - 1)}
              label="Go"
              css={style.button}
              style={{ borderRadius: '0 4px 4px 0',
              fontSize: '14px',
              height: '2.55em',
               }}
            ></Button>
          </div>
        </div>
      )}
    </div>
  ) : (
    <>
      {
        Empty ?
          <>{Empty()}</>
          :
          <EmptyResults />
      }
    </>
  );
};

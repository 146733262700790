/** @jsx jsx */
import { jsx } from "@emotion/react";

import { SearchTable } from "../base";
import IndexRow from "./row";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import { IndexFilters } from "./index-filters";
import { IndexSearchResultDto } from "../../../../../interfaces/search/search";
import { containerCss, resultCss } from "../index.style";
import IndexHeader from "./header";
import { InputSort } from "../base/sort";
import { Loader } from "../../Loader";
import SearchResults from "../../../../../interfaces/search/search-result";
import IndexEmptyResults from "./empty";
import useFilterHandlers from '../../../../hooks/search/useFilterHandlers';
import { OptionsData } from '@bdl-cmn-shared-packages-npm/design-system';
import useTagFilters from '../../../Search/results/useTagFilters';
import { FilterTags } from '../../../Search/results/filters/filter-tags';
import { CountTags } from '../../../Search/results/filters/count-tags';
import { style } from '../../../Search/results/filters/filter-tags.style';

export interface IndexesListViewProps {
  selectedCols: any;
  history?: any;
  data: IndexSearchResultDto;
  searchResults?: SearchResults;
  target?: string;
  onPaginationUpdate: (pageNumber: number) => void;
  currentPageNumber: number;
  density?: "low" | "medium" | "high";
  itemsNumber?: number;
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
  loadingPage?: boolean
}

export const IndexesListView = ({ 
  data,
  searchResults,
  loadingPage,
  selectedCols,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
}: IndexesListViewProps) => {
  let tmpData: OptionsData[] = [];
  const tagFilters = useTagFilters(filters);
  
  const { handleResetFilter, handleResetAllFilters } = useFilterHandlers(filters, onFiltersChange, tagFilters, tmpData);
  
  return (
    <div css={containerCss}>
      <IndexFilters
        filterCount={data && data.filters}
        filters={filters}
        onFiltersChange={onFiltersChange}
      />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <CountTags
          nbResults={data?.totalHits}
          filters={tagFilters.indices}
        />
        <div css={style.filterContainer}>
          <FilterTags
            nbResults={data?.totalHits}
            filters={tagFilters.indices}
            onFilterClear={handleResetFilter}
            onReset={handleResetAllFilters}
            label="Indices"
            searchTerm={''}
            loader={loadingPage}
          />
          <InputSort
            sort={sort}
            baseValues={sortData ? sortData['indices'] : {}}
            onSortChange={onSortChange}
          />
        </div>
        <SearchTable
          data={data?.indexes}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <IndexHeader selectedCols={selectedCols} />}
          Row={(data) => <IndexRow row={data} selectedCols={selectedCols} key={`search-indice-r-${data?.id}`} />}
          Empty={() => <div css={{ marginTop: "70px" }}><IndexEmptyResults searchResults={searchResults} /></div>}
        />
      </div>
    </div>
  );
};

/* eslint-disable */
import * as React from "react";

import { useCallback, useState } from 'react';
import { DEFAULT_FILTERS, Filters } from "../../components/Search/useFilters";

const useFilterHandlers = (filters: any, onFiltersChange: any, tagFilters: any, tmpData: any) => {

    const [loader, setLoader] = React.useState<boolean>(false);

    const handleResetFilter = useCallback(
        (key: keyof Filters, value: any) => {
          let values;
          if (filters[key] instanceof Array) {
              try {
                let array: any[] = filters[key] != null ? Object.assign([], filters[key]) : [];
                if (array != null && array.includes(value))
                  array = array.filter((v) => {
                    return v !== value;
                  });
    
                values = array && array.length ? array : DEFAULT_FILTERS[key];
              } catch (e) {
                console.error("Failed to reset filter '" + key + "'", value);
              }
          }
          else if (filters[key] instanceof Boolean) values = !filters[key];
          else values = DEFAULT_FILTERS[key];
    
          onFiltersChange({
            ...filters,
            [key]: values,
          });
          setLoader(false);
    
        },
        [filters, onFiltersChange,loader,tagFilters,tmpData]
      );
    
      const handleResetAllFilters = useCallback(() => {
        onFiltersChange({
          ...DEFAULT_FILTERS,
          q: filters.q,
          dataType: filters.dataType,
        });
      }, [filters, onFiltersChange]);

      return { handleResetFilter, handleResetAllFilters };
};

export default useFilterHandlers;
/** @jsx jsx */

import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  Text,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";

export interface CurrentOffersContainerProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const CurrentOffersContainer: React.FunctionComponent<CurrentOffersContainerProps> =
  (props: CurrentOffersContainerProps): React.ReactElement => {
    const { fields, rendering } = props;

    const parentContainer = css`
      width: 100%;
      margin-bottom: ${fields.paddingOrNot ? `${fields.paddingPixels.value}px` : ''};
    `;

    const container = css`
      width: 100%;
      padding: 80px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      @media (max-width: ${breakpointMax}) {
        padding: 16px;
      }
    `;

    const cols = css`
      width: 100%;
      display: flex;
      flex-direction: row;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
      }
    `;

    const title = css`
      text-align: center;
      font-weight: 700;
      font-size: 48px;
      line-height: 61px;
      color: #354450;
      margin-bottom: 76px;
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 32px;
      }
    `;

    const actionButton = css`
      margin-top: 32px;
      display: flex;
      justify-content: center;
      margin-left: 16px;
      @media (max-width: ${breakpointMax}) {
        margin-top: 16px;
        justify-content: flex-start;
        button {
          font-size: 12px;
        }
      }
    `;

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={title}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={cols}>
            <Placeholder name="jss-current-offers" rendering={rendering} />
          </div>
        </div>
        <div css={actionButton}>
          <Button
            label={
              fields &&
              fields[`moreLinkLabel`] &&
              (fields && (fields[`moreLinkLabel`] as any)).value
            }
            onClick={() =>
              canUseDOM &&
              window.open(
                fields &&
                  fields[`moreLink`] &&
                  (fields && (fields[`moreLink`] as any)).value,
                "_blank"
              )
            }
            iconRight={"arrow-right"}
          />
        </div>
      </div>
    );
  };


/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import Loader from "../../core/components/Loader/loader.component";
import { useOutages } from "../../core/hooks/market/useOutages";
import { MarketOutage } from "../../interfaces/market-outage";
import { parseToMoment } from "../../utils/date";
import { SpecificYearOutages } from "./SpecificYearOutages";

export interface MarketCompleteOutagesHistoryProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
    gap: 16px;
    margin-bottom: 48px;
`;

const title = css`
    font-weight: bold;
`;

export const MarketCompleteOutagesHistory: React.FunctionComponent<MarketCompleteOutagesHistoryProps> = (props: MarketCompleteOutagesHistoryProps): React.ReactElement => {
    const { fields } = props;

    const noRecordsMessageValue = getFieldValue(fields, 'noRecordsMessage', '');
    const { isLoading, outages } = useOutages();
    const firstKey = React.useMemo(() => {
        if(outages && outages[0] && outages[0].dtCre){
            return parseToMoment(outages[0].dtCre).format('yyyy')
        }
        return null;
    }, [outages]);

    const sortedOutages: Map<string, Map<string, MarketOutage[]>> = React.useMemo(() => {
        const map = new Map<string, Map<string, MarketOutage[]>>();
        outages.forEach(outage => {
            const date = parseToMoment(outage.dtCre);
            const year = date.format('yyyy');
            const formattedDate = date.format('DD/MM/yyyy');
            if(!map.has(year)) {
                map.set(year, new Map<string, MarketOutage[]>());
            }
            if(!map.get(year).has(formattedDate)) {
                map.get(year).set(formattedDate, []);
            }
            map.get(year).get(formattedDate).push(outage);
        })
        return map;
    }, [outages]);

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            <h1 css = {title}>{headingValue}</h1>
            <Loader loading={isLoading}>
                {
                    outages && outages.length > 0
                    ? Array.from(sortedOutages, ([key, value]) => <SpecificYearOutages key={key} year={key} outagesPerDate={value} isOpenByDefault={key===firstKey}/>)
                    : <p>{noRecordsMessageValue}</p>
                }
            </Loader>
        </div>
    );
}
    
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { splitFilterValues } from '../../../../hooks/search/useFilters';
import { getFilterLabel } from '../../../../hooks/search/useFilters';
import { Link, Loader, Tag } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from './filter-tags.style';
import { FilterTag } from '../useTagFilters';
import ArrowsRotate from '../../../../icons/arrows-rotate';

export interface FilterTagsProps<T> {
  nbResults: number;
  filters: FilterTag<T>[];
  onFilterClear: (key: keyof T, value: string) => void;
  onReset: () => void;
  label?: string;
  searchTerm: string;
  loader: boolean;
}

export function FilterTags<T>(props: FilterTagsProps<T>) {
  const filterLang: any = props.filters.find((x: any) => x.name === 'lang');
  return (
    <div css={style.container}>
      <Loader loading={props.loader} centered css={{ height: 20 }}>
        <div css={style.tagsWrapper}>
          {props.filters?.map((filter) => {
            const values = splitFilterValues(filter.value);
            const tags = [];
            if (values != null && values.length)
              for (let index = 0; index < values.length; index++) {
                if (values[index] != null && values[index].length)
                  tags.push(
                    <Tag
                      key={filter.name.toString()}
                      label={getFilterLabel(
                        props.label,
                        filter.name.toString(),
                        values[index]
                      )}
                      onClose={() => props.onFilterClear(filter.name, values[index])}
                    // style= {{ backgroundColor: '#edf1f5 !important' }}
                    />
                  );
              }
            return tags;
          })}
        </div>
      </Loader>
      {props.filters?.length > 0 ? (
        <div css={style.resetLink}>
          <Link href="#" onClick={props.onReset}>
            <div css={style.closeBTN}>
              <ArrowsRotate style={{}} />
            </div>
            {filterLang?.value === 'FR'
              ? 'Réinitialiser tous les filtres'
              : 'Reset all filters'}
          </Link>
        </div>
      ) : null}
    </div>
  );
}
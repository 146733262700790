import gql from "graphql-tag";
import {
  documentSearchEmptyFragment,
  documentSearchFullFragment,
} from "./search-document.queries";
import {
  indexSearchEmptyFragment,
  indexSearchFullFragment,
} from "./search-index.queries";
import {
  issuerSearchEmptyFragment,
  issuerSearchFullFragment,
} from "./search-issuer.queries";
import {
  securityLuxXPrimeSearchEmptyFragment,
  securityLuxXPrimeSearchFullFragment,
} from "./search-luxxprime.queries";
import { pageSearchFullFragment } from "./search-page.queries";
import {
  programmeSearchEmptyFragment,
  programmeSearchFullFragment,
} from "./search-programme.queries";
import {
  securitySearchEmptyFragment,
  securitySearchFullFragment,
} from "./search-security.queries";

export const GET_SEARCH_SECURITIES_RESULTS = gql`
  ${securitySearchFullFragment}
  ${securityLuxXPrimeSearchEmptyFragment}
  ${issuerSearchEmptyFragment}
  ${programmeSearchEmptyFragment}
  ${documentSearchEmptyFragment}
  ${indexSearchEmptyFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchFullFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: 0
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchEmptyFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchEmptyFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchEmptyFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchEmptyFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchEmptyFragment
    }
  }
`;

export const GET_SEARCH_SECURITIES_LUXXPRIME_RESULTS = gql`
  ${securitySearchEmptyFragment}
  ${securityLuxXPrimeSearchFullFragment}
  ${issuerSearchEmptyFragment}
  ${programmeSearchEmptyFragment}
  ${documentSearchEmptyFragment}
  ${indexSearchEmptyFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $page: Int
    $sortBy: String!
    $sortDir: SortDIR!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchEmptyFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: $page
      sortBy: $sortBy
      sortDir: $sortDir
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchFullFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchEmptyFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchEmptyFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchEmptyFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchEmptyFragment
    }
  }
`;

export const GET_SEARCH_ISSUERS_RESULTS = gql`
  ${securitySearchEmptyFragment}
  ${securityLuxXPrimeSearchEmptyFragment}
  ${issuerSearchFullFragment}
  ${programmeSearchEmptyFragment}
  ${documentSearchEmptyFragment}
  ${indexSearchEmptyFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchEmptyFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: 0
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchEmptyFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchFullFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchEmptyFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchEmptyFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchEmptyFragment
    }
  }
`;

export const GET_SEARCH_PROGRAMMES_RESULTS = gql`
  ${securitySearchEmptyFragment}
  ${securityLuxXPrimeSearchEmptyFragment}
  ${issuerSearchEmptyFragment}
  ${programmeSearchFullFragment}
  ${documentSearchEmptyFragment}
  ${indexSearchEmptyFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchEmptyFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: 0
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchEmptyFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchEmptyFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchFullFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchEmptyFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchEmptyFragment
    }
  }
`;

export const GET_SEARCH_DOCUMENTS_RESULTS = gql`
  ${securitySearchEmptyFragment}
  ${securityLuxXPrimeSearchEmptyFragment}
  ${issuerSearchEmptyFragment}
  ${programmeSearchEmptyFragment}
  ${documentSearchFullFragment}
  ${indexSearchEmptyFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchEmptyFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: 0
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchEmptyFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchEmptyFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchEmptyFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchFullFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchEmptyFragment
    }
  }
`;

export const GET_SEARCH_INDEXES_RESULTS = gql`
  ${securitySearchEmptyFragment}
  ${securityLuxXPrimeSearchEmptyFragment}
  ${issuerSearchEmptyFragment}
  ${programmeSearchEmptyFragment}
  ${documentSearchEmptyFragment}
  ${indexSearchFullFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchEmptyFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: 0
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchEmptyFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchEmptyFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchEmptyFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchEmptyFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchFullFragment
    }
  }
`;

export const GET_SEARCH_PAGES_RESULTS = gql`
  ${securitySearchEmptyFragment}
  ${securityLuxXPrimeSearchEmptyFragment}
  ${issuerSearchEmptyFragment}
  ${programmeSearchEmptyFragment}
  ${documentSearchEmptyFragment}
  ${indexSearchEmptyFragment}

  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String!
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchEmptyFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: 0
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchEmptyFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchEmptyFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchEmptyFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      page: 0
      size: 1
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchEmptyFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      size: 1
      page: 0
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchEmptyFragment
    }
  }
`;

export const GET_SEARCH_RESULTS = gql`
  ${securitySearchFullFragment}
  ${securityLuxXPrimeSearchFullFragment}
  ${issuerSearchFullFragment}
  ${programmeSearchFullFragment}
  ${documentSearchFullFragment}
  ${indexSearchFullFragment}
  query GetLuxseSearchResults(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $excludeRetr: Boolean
    $category: LuxseSearchSecurityCategoryFilter
    $marketRegulation: MarketRule
    $currency: Currency
    $country: String
    $issuerType: LuxseSearchIssuersTypeFilter
    $luxXPrimeOnly: Boolean
    $chineseBondOnly: Boolean
    $genderBondOnly: Boolean
    $fromDate: Date
    $toDate: Date
    $sdgs: [SDGEligibilityLink!]
    $sustainableClassification: [RBDLTags!]
    $levelOneClassifications: [LevelOneClassification!]
    $levelTwoClassifications: [LevelTwoClassification!]
    $couponType: [InstrumentSubType!]
    $currencies: [Currency!]
    $yieldFilterFrom: Float
    $yieldFilterTo: Float
    $interestRateFilterFrom: Float
    $interestRateFilterTo: Float
    $maturityDateFilterFrom: Date
    $maturityDateFilterTo: Date
    $includePerpetualFilter: Boolean
    $firstTradingDateFilterFrom: Date
    $firstTradingDateFilterTo: Date
    $periodicityFilter: [PeriodicityCode!]
    $eligibleProjectCategories: [GreenBondProjectCategories!]
    $countryFilter: [Country!]
    $securityStatuses: [String!]
    $securityStatusesOperator: String
    $securityExcludeStatuses: [String!]
    $securityMarketRules: [String!]
    $securityExcludeMarketRules: [String!]
    $tokenTypes: [String!]
    $tokenOnly: Boolean
    $lgxSustainableBonds: [String!]
    $lgxSustainableFunds: [String!]
    $lgxStandards: [String!]
    $lgxExternalReviewOnly: Boolean
    $lgxExternalReportingOnly: Boolean
    $lgxPostIssuanceOnly: Boolean
    $lgxSdgs: [String!]
    $lgxEligibleCategories: [String!]
    $lgxSlbKpiThemes: [String!]
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $documentTypes: [String!]
    $documentSubTypes: [String!]
    $excludeDocumentTypes: [String!]
    $excludeDocumentSubTypes: [String!]
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $programmeActiveOnly: Boolean
    $indexTypes: [String!]
    $indexCurrencies: [String!]
    $indexLatestPriceDate: String
    $issuerIdFilter: [Int!]
  ) {
    luxseSecuritiesSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      luxXPrimeOnly: $luxXPrimeOnly
      excludeRetr: $excludeRetr
      category: $category
      marketRegulation: $marketRegulation
      currency: $currency
      chineseBondOnly: $chineseBondOnly
      genderBondOnly: $genderBondOnly
      statuses: $securityStatuses
      statusesOperator: $securityStatusesOperator
      excludeStatuses: $securityExcludeStatuses
      marketRules: $securityMarketRules
      excludeMarketRules: $securityExcludeMarketRules
      tokenTypes: $tokenTypes
      tokenOnly: $tokenOnly
      lgxSustainableBonds: $lgxSustainableBonds
      lgxSustainableFunds: $lgxSustainableFunds
      lgxStandards: $lgxStandards
      lgxExternalReviewOnly: $lgxExternalReviewOnly
      lgxExternalReportingOnly: $lgxExternalReportingOnly
      lgxPostIssuanceOnly: $lgxPostIssuanceOnly
      lgxSdgs: $lgxSdgs
      lgxEligibleCategories: $lgxEligibleCategories
      lgxSlbKpiThemes: $lgxSlbKpiThemes
    ) {
      ...securitySearchFullFragment
    }

    luxXPrimeSearch(
      textInput: $searchTerm
      pageNumber: $page
      sdgIntendedFilter: $sdgs
      tagListFilter: $sustainableClassification
      categoryFilter: $levelOneClassifications
      subCategoryFilter: $levelTwoClassifications
      couponTypeFilter: $couponType
      currencyFilter: $currencies
      yieldFilterFrom: $yieldFilterFrom
      yieldFilterTo: $yieldFilterTo
      interestRateFilterFrom: $interestRateFilterFrom
      interestRateFilterTo: $interestRateFilterTo
      firstTradingDateFilterFrom: $firstTradingDateFilterFrom
      firstTradingDateFilterTo: $firstTradingDateFilterTo
      maturityDateFilterFrom: $maturityDateFilterFrom
      maturityDateFilterTo: $maturityDateFilterTo
      includePerpetualFilter: $includePerpetualFilter
      periodicityFilter: $periodicityFilter
      eligibleProjectCategories: $eligibleProjectCategories
      countryFilter: $countryFilter
      issuerIdFilter: $issuerIdFilter
    ) {
      ...securityLuxXPrimeSearchFullFragment
    }

    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      type: $issuerType # TODO remove me
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchFullFragment
    }

    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      activeOnly: $programmeActiveOnly
    ) {
      ...programmeSearchFullFragment
    }

    luxseDocumentsSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      fromDate: $fromDate
      toDate: $toDate
      types: $documentTypes
      subTypes: $documentSubTypes
      excludeTypes: $excludeDocumentTypes
      excludeSubTypes: $excludeDocumentSubTypes
    ) {
      ...documentSearchFullFragment
    }

    luxseIndexesSearch(
      searchTerm: $searchTerm
      page: $page
      size: $size
      sort: $sort
      types: $indexTypes
      currencies: $indexCurrencies
      latestPriceDate: $indexLatestPriceDate
    ) {
      ...indexSearchFullFragment
    }
  }
`;
/** @jsx jsx */

import React from "react";
import { css, jsx } from "@emotion/react";

import { DataType } from "../../../../../../enums";
import ProgrammeSearchCard from "../../../../UI/list/programme/card-mobile";
import { SearchMobile } from "../../../../UI/list/base/index-mobile";
import SecuritySearchCard from "../../../../UI/list/security/card-mobile";
import { style } from "./index.style";
import IssuerSearchCard from "../../../../UI/list/issuer/card-mobile";
import DocumentSearchCard from "../../../../UI/list/document/card-mobile";
import IndexSearchCard from "../../../../UI/list/index/card-mobile";
import LuxXPrimeSearchCard from "../../../../UI/list/luxXPrime/card-mobile";
import SecurityEmptyResults from "../../../../UI/list/security/empty";
import IndexEmptyResults from "../../../../UI/list/index/empty";
import DocumentEmptyResults from "../../../../UI/list/document/empty";
import ProgrammeEmptyResults from "../../../../UI/list/programme/empty";
import IssuerEmptyResults from "../../../../UI/list/issuer/empty";
import LuxXPrimeEmptyResults from "../../../../UI/list/luxXPrime/empty";
import ContentSearchCard from "../../../../UI/list/contentSearch/card-mobile";
import ContentEmptyResults from "../../../../UI/list/contentSearch/empty";
import SearchResults from "../../../../../../interfaces/search/search-result";

export interface ResultsCardsProps {
  data?: SearchResults;
  dataType?: string;
  onPaginationUpdate?: (pageNumber: number) => void;
  currentPageNumber?: number
}

export const ResultsCards = (props: ResultsCardsProps): JSX.Element => {
  const { data, dataType, onPaginationUpdate, currentPageNumber } = props;

  return (
    <div css={style.container}>
      {dataType?.toLowerCase() == DataType.all || dataType?.toLowerCase() == DataType.webpage || dataType?.toLowerCase() == DataType.blogpost || dataType?.toLowerCase() == DataType.pressrelease || dataType?.toLowerCase() == DataType.brochure || dataType?.toLowerCase() == DataType.form ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.webPages?.results}
          totalResultSize={data?.webPages?.totalHits}
          Card={(card) => (
            <ContentSearchCard key={`card-index-${card?.id}`} card={card} />
          )}
          Empty={() => <ContentEmptyResults searchResults={data}/>}
        />
      ) : dataType?.toLowerCase() == DataType.securities ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.securities?.securities}
          totalResultSize={data?.securities?.totalHits}
          Card={(card) => (
            <SecuritySearchCard key={`card-security-${card?.id}`} card={card} />
          )}
          Empty={() => <SecurityEmptyResults searchResults={data}/>}
        />
      ) : dataType?.toLowerCase() == DataType.luxXPrime.toLowerCase() ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.luxXPrime?.securities}
          totalResultSize={data?.luxXPrime?.totalHits}
          Card={(card) => (
            <LuxXPrimeSearchCard key={`card-luxxprime-${card?.id}`} card={card} />
          )}
          Empty={() => <LuxXPrimeEmptyResults searchResults={data}/>}
        />
      ) : dataType?.toLowerCase() == DataType.issuers ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.issuers?.issuers}
          totalResultSize={data?.issuers?.totalHits}
          Card={(card) => (
            <IssuerSearchCard key={`card-issuer-${card?.id}`} card={card} />
          )}
          Empty={() => <IssuerEmptyResults searchResults={data}/>}
        />
      ) : dataType?.toLowerCase() == DataType.programmes ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.programmes?.programmes}
          totalResultSize={data?.programmes?.totalHits}
          Card={(card) => (
            <ProgrammeSearchCard key={`card-programme-${card?.id}`} card={card} />
          )}
          Empty={() => <ProgrammeEmptyResults searchResults={data}/>}
        />
      ) : dataType?.toLowerCase() == DataType.documents ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.documents?.documents}
          totalResultSize={data?.documents?.totalHits}
          Card={(card) => (
            <DocumentSearchCard key={`card-document-${card?.id}`} card={card} />
          )}
          Empty={() => <DocumentEmptyResults searchResults={data}/>}
        />
      ) : dataType?.toLowerCase() == DataType.indices ? (
        <SearchMobile
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          data={data?.indexes?.indexes}
          totalResultSize={data?.indexes?.totalHits}
          Card={(card) => (
            <IndexSearchCard key={`card-index-${card?.id}`} card={card} />
          )}
          Empty={() => <IndexEmptyResults searchResults={data}/>}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ResultsCards;
